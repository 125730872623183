<template>
    <div
        class="bg-20 h-9 px-3 text-center rounded-b-lg flex items-center justify-between"
    >
        <p class="leading-normal text-sm text-gray-500">
            {{ resourceCountLabel }}
        </p>

        <p
            v-if="allResourcesLoaded"
            class="leading-normal text-sm"
        >
            All {{ resourceInformation.label }} loaded
        </p>

        <v-button
            v-else
            :label="buttonLabel"
            :loading="loadingMore"
            severity="muted"
            variant="ghost"
            @click="loadMore"
        />

        <p class="leading-normal text-sm text-gray-500">
            {{ __(":amount Total", { amount: resourceTotalCountLabel }) }}
        </p>
    </div>
</template>

<script>
    import VButton from "@nova/components/Buttons/VButton.vue";

    export default {
        components: {
            VButton,
        },

        inject: ["resourceInformation"],

        props: {
            currentResourceCount: {
                type: Number,
                required: true,
            },

            allMatchingResourceCount: {
                type: Number,
                required: true,
            },

            resourceCountLabel: {
                type: String,
                required: true,
            },

            perPage: {
                type: [Number, String],
                required: true,
            },

            page: {
                type: Number,
                required: true,
            },

            pages: {
                type: Number,
                default: 0,
            },

            next: {
                type: Boolean,
                default: false,
            },

            previous: {
                type: Boolean,
                default: false,
            },

            loadingMore: {
                type: Boolean,
                default: false,
            },
        },

        emits: ["load-more"],

        computed: {
            buttonLabel() {
                return this.__("Load :perPage More", {
                    perPage: Nova.formatNumber(this.perPage),
                });
            },

            allResourcesLoaded() {
                return (
                    this.currentResourceCount == this.allMatchingResourceCount
                );
            },

            resourceTotalCountLabel() {
                return Nova.formatNumber(this.allMatchingResourceCount);
            },
        },

        methods: {
            loadMore() {
                this.$emit("load-more");
            },
        },
    };
</script>
