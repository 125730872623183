<script>
    import Froala from "@js/froala/Froala";
    const editorNames = Object.keys(Froala);
</script>

<script setup>
    import { computed, onMounted, ref, watch, reactive } from "vue";
    import { snakeCase } from "lodash";

    const props = defineProps({
        name: {
            type: String,
            required: true,
        },
        editor: {
            type: String,
            default: editorNames[0],
            validator: (value) => editorNames.includes(value),
        },
        config: {
            type: Object,
            default: () => ({}),
        },
        height: {
            type: Number,
            default: 250,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        enterKey: {
            type: String,
            default: "P",
            validator: (value) => ["P", "BR", "DIV"].includes(value),
        },
    });

    const value = defineModel();
    const ready = ref(false);
    let config = reactive({});

    async function setupEditorConfig() {
        const editor = new Froala[props.editor]();

        await editor.loadFroalaEditor();
        await editor.setEnterKey(props.enterKey);

        editor.setOptions({
            ...{ height: props.height },
            ...props.config,
        });

        config = editor.config();
    }

    watch(
        () => props.editor,
        async (editor) => {
            ready.value = false;
            await setupEditorConfig();
            setTimeout(() => {
                ready.value = true;
            }, 50);
        },
        { immediate: true },
    );
</script>

<template>
    <div>
        <FroalaEditor
            v-if="ready"
            v-model:value="value"
            :id="`froala-${props.name}`"
            :tag="'textarea'"
            :config="config"
        />
    </div>
</template>
