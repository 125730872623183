<template>
    <filter-container>
        <span>{{ filter.name }}</span>

        <template #filter>
            <select-control
                v-model:selected="value"
                :dusk="`${filter.name}-select-filter`"
                :options="filter.options"
                @change="value = $event"
            >
                <option
                    :selected="value == ''"
                    value=""
                >
                    {{ __("&mdash;") }}
                </option>
            </select-control>
        </template>
    </filter-container>
</template>

<script>
    import debounce from "lodash/debounce";
    import FilterContainer from "@nova/components/Filters/FilterContainer.vue";
    import SelectControl from "@nova/components/Inputs/SelectControl.vue";

    export default {
        components: {
            FilterContainer,
            SelectControl,
        },

        props: {
            resourceName: {
                type: String,
                required: true,
            },

            filterKey: {
                type: String,
                required: true,
            },

            lens: String,
        },

        emits: ["change"],

        data: () => ({
            value: null,
            debouncedHandleChange: null,
        }),

        computed: {
            filter() {
                return this.$store.getters[`${this.resourceName}/getFilter`](
                    this.filterKey,
                );
            },
        },

        watch: {
            value() {
                this.debouncedHandleChange();
            },
        },

        created() {
            this.debouncedHandleChange = debounce(
                () => this.handleChange(),
                500,
            );
            this.setCurrentFilterValue();
        },

        mounted() {
            Nova.$on("filter-reset", this.setCurrentFilterValue);
        },

        beforeUnmount() {
            Nova.$off("filter-reset", this.setCurrentFilterValue);
        },

        methods: {
            setCurrentFilterValue() {
                this.value = this.filter.currentValue;
            },

            handleChange() {
                this.$store.commit(`${this.resourceName}/updateFilterState`, {
                    filterClass: this.filterKey,
                    value: this.value,
                });

                Nova.$emit("filter-changed", {
                    filterClass: this.filterKey,
                    value: this.filter.currentValue,
                });

                this.$emit("change");
            },
        },
    };
</script>
