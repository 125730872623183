import Emitter from "tiny-emitter";
import Mousetrap from "mousetrap";
import NProgress from "nprogress";
import Toastify from "toastify-js";
import get from "lodash/get";
import isNil from "lodash/isNil";
import isString from "lodash/isString";
import omit from "lodash/omit";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "/tailwind.config.js";
import { Axios, Url } from "./plugins";
import { createApp, h } from "vue";
import { createInertiaApp, Link, router } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { formatNumber, setupNumbro, setupAxios, url } from "@nova/utils";

const emitter = new Emitter();
const { theme } = resolveConfig(tailwindConfig);

export class InertiaApp {
    constructor(shared) {
        this.appConfig = shared.appConfig;
        this.icons = shared.icons;
        this.useShortcuts = true;

        this.pages = this.inertiaPages();
        this.$progress = NProgress;
        this.$router = router;
        this.$shared = shared;
    }

    async countdown() {
        this.options = this.inertiaOptions(theme);

        await createInertiaApp(this.options);

        const lang =
            document.querySelector('meta[name="locale"]')?.content ||
            document.querySelector("html").getAttribute("lang") ||
            "en";

        await setupNumbro(lang);

        return this;
    }

    inertiaPages() {
        return {};
    }

    inertiaOptions(theme) {
        const appName = this.config("appName");

        return {
            title: (title) => this.resolveTitle(title, appName),
            resolve: async (name) => await this.resolvePage(name),
            setup: ({ el, App, props, plugin }) => {
                this.inertiaSetup(el, App, props, plugin);
            },
        };
    }

    resolveTitle(title, appName) {
        return `${title} | ${appName}`;
    }

    async resolvePage(name) {
        let page = null;
        let error = null;

        try {
            page = await resolvePageComponent(name, this.pages);
        } catch (e) {
            error = e;
        }

        if (isNil(page)) {
            page = this.resolveNotFoundPage(name);
        }

        if (error) {
            throw error;
        }

        page.default.layout = page.default.layout || this.inertiaLayout();

        return page;
    }

    resolveNotFoundPage(name) {
        if (this.config("debug")) {
            this.error(`Page "${name}" not found.`);
        } else {
            window.location.pathname = "/404";
        }
    }

    inertiaLayout() {
        return null;
    }

    inertiaSetup(el, App, props, plugin) {
        /**
         * Delete title element from the head section of the page so that inertia can override it
         * @see https://inertiajs.com/title-and-meta
         */
        const title = document.querySelector("title");

        if (title) {
            title.remove();
        }

        this.mountTo = el;
        this.app = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(Axios, { inertia: this })
            .use(Url, { inertia: this })
            .component("Link", Link);

        this.vueSetup(theme);
    }

    vueSetup(theme) {
        //
    }

    liftOff() {
        this.app.mount(this.mountTo);

        this.$on("error", (message) => this.handleError(message));
        this.$on("token-expired", () => this.handleTokenExpired());

        this.registerMousetrap();

        return this;
    }

    registerMousetrap() {
        const mousetrapDefaultStopCallback = Mousetrap.prototype.stopCallback;

        Mousetrap.prototype.stopCallback = (e, element, combo) => {
            if (!this.useShortcuts) {
                return true;
            }

            return mousetrapDefaultStopCallback.call(this, e, element, combo);
        };

        Mousetrap.init();
    }

    config(key) {
        return get(this.appConfig, key);
    }

    icon(key) {
        return get(this.icons, key);
    }

    request(options) {
        const axios = setupAxios();

        if (options !== undefined) {
            return axios(options);
        }

        return axios;
    }

    url(path, parameters) {
        return url("/", path, parameters);
    }

    $on(...args) {
        emitter.on(...args);
    }

    $once(...args) {
        emitter.once(...args);
    }

    $off(...args) {
        emitter.off(...args);
    }

    $emit(...args) {
        emitter.emit(...args);
    }

    addShortcut(keys, callback) {
        Mousetrap.bind(keys, callback);
    }

    disableShortcut(keys) {
        Mousetrap.unbind(keys);
    }

    pauseShortcuts() {
        this.useShortcuts = false;
    }

    resumeShortcuts() {
        this.useShortcuts = true;
    }

    page(name, component) {
        this.pages[name] = component;
    }

    component(name, component) {
        this.app.component(name, component);
    }

    /**
     * @link https://github.com/apvarun/toastify-js
     *
     * @param {String} message
     * @param {String="info"} type ("info"|"error"|"warning"|"success")
     * @param {Object} options
     */
    toast(message, type = "info", options = {}) {
        const baseClass = [
            "fixed right-0 bottom-0 z-50",
            "py-2 px-4 mr-4 max-w-1/2",
            "text-base text-white bg-none",
            "rounded shadow-md drop-shadow-md",
            "cursor-pointer transition-all",
        ].join(" ");

        const typeClass = {
            info: "bg-blue-500",
            error: "bg-red-500",
            success: "bg-green-700",
            warning: "bg-yellow-500",
        }[type];

        Toastify({
            text: message,
            duration: 6000,
            className: baseClass + " " + typeClass,
            gravity: "bottom",
            position: "right",
            stopOnFocus: true,
            close: false,
            escapeMarkup: false,
            ...options,
        }).showToast();
    }

    /** @param {String} message */
    info(message, options = {}) {
        this.toast(message, "info", options);
    }

    /** @param {String} message */
    error(message, options = {}) {
        this.toast(message, "error", options);
    }

    /** @param {String} message */
    success(message, options = {}) {
        this.toast(message, "success", options);
    }

    /** @param {String} message */
    warning(message, options = {}) {
        this.toast(message, "warning", options);
    }

    formatNumber(number, format) {
        return formatNumber(number, format);
    }

    redirectToLogin() {
        const url =
            !this.config("withAuthentication") && this.config("customLoginPath")
                ? this.config("customLoginPath")
                : this.url("/login");

        this.visit({
            remote: true,
            url,
        });
    }

    /**
     * Visit page using Inertia visit or window.location for remote.
     *
     * @param {Object|String}   path
     * @param {Object=|String=} path.url
     * @param {Boolean=}        path.remote
     * @param {Object}          options
     * @param {Boolean=}        options.openInNewTab
     * @param {String=}         options.method
     * @param {Object=}         options.data
     */
    visit(path, options = {}) {
        const openInNewTab = options?.openInNewTab || false;

        if (isString(path)) {
            router.visit(this.url(path), omit(options, ["openInNewTab"]));
            return;
        }

        if (isString(path.url)) {
            if (path.remote === true) {
                if (openInNewTab === true) {
                    window.open(path.url, "_blank");
                } else {
                    window.location = path.url;
                }

                return;
            }

            router.visit(path.url, omit(options, ["openInNewTab"]));
        }
    }

    handleError(message) {
        this.error(message);
    }

    handleTokenExpired() {
        this.error("Sorry, your session has expired.", {
            onClick: () => this.redirectToLogin(),
        });

        setTimeout(() => {
            this.redirectToLogin();
        }, 5000);
    }
}
