<template>
    <div class="flex flex-col gap-2 -mb-2">
        <h4
            v-if="title"
            class="m-0 p-0"
            v-text="title"
        />
        <div
            v-if="!shouldShowChart"
            class="flex flex-col items-center justify-center absolute inset-0 pointer-events-none"
            :style="{ color: colors.muted }"
        >
            <div>Insufficient Data!</div>
            <div
                v-if="!sufficientStd"
                class="text-xs"
            >
                &sigma; must be > 0
            </div>
            <div
                v-if="!sufficientPopulation"
                class="text-xs"
            >
                n must be <u>></u> 30
            </div>
        </div>
        <div
            v-show="shouldShowChart"
            class="flex-1"
        >
            <canvas ref="chart"></canvas>
        </div>
        <help-tooltips>
            <help-distribution-tooltip
                :format="format"
                :mean="data.mean"
                :population="data.population"
                :range="distributionRange"
                :std="data.std"
            />
            <help-range-tooltip :range="range" />
            <slot name="help" />
        </help-tooltips>
    </div>
</template>

<script>
    import max from "lodash/max";
    import BaseRangedChartMetric from "./BaseRangedChartMetric.vue";
    import HelpTooltips from "@nova/components/HelpTooltips.vue";
    import HelpDistributionTooltip from "@nova/components/HelpDistributionTooltip.vue";
    import HelpRangeTooltip from "@nova/components/HelpRangeTooltip.vue";

    export default {
        components: {
            HelpTooltips,
            HelpDistributionTooltip,
            HelpRangeTooltip,
        },

        extends: BaseRangedChartMetric,

        props: {
            distributionRange: Object,
        },

        computed: {
            labels() {
                return this.data.partition.map((v) => v.label);
            },

            values() {
                return this.data.partition.map((v) => v.value);
            },

            zLabels() {
                return this.labels.map((v) => this.zValue(v));
            },

            step() {
                return this.labels[1] - this.labels[0];
            },

            probability() {
                return this.labels.map((label) => {
                    const step = this.step;

                    return this.normalArea(label - step / 2, label + step / 2);
                });
            },

            shrinkPartition() {
                return this.data.partition
                    .map((item, i) => ({
                        ...item,
                        original: i,
                    }))
                    .filter((item, i) => this.probability[i] > 0.001);
            },

            shrinkLabels() {
                return this.shrinkPartition.map((v) => v.label);
            },

            shrinkValues() {
                return this.shrinkPartition.map((v) => v.value);
            },

            shrinkProbability() {
                return this.shrinkPartition.map(
                    (v) => this.probability[v.original],
                );
            },

            scaleProbability() {
                const maxP = max(this.shrinkProbability);
                const maxV = max(this.shrinkValues);

                return this.shrinkProbability.map((v) => (v / maxP) * maxV);
            },

            chartType() {
                return "bar";
            },

            chartData() {
                if (!this.data.partition) {
                    return {};
                }

                return {
                    labels: this.shrinkLabels,
                    datasets: [
                        {
                            is: "distribution",
                            type: "line",
                            xAxisID: "xNorm",
                            label: "Probability",
                            data: this.scaleProbability,
                            borderColor: this.colors.secondary,
                            borderWidth: 1,
                            pointBackgroundColor: this.colors.secondary,
                            pointRadius: 0,
                            pointHoverRadius: 3,
                            tension: 0.5,
                        },
                        {
                            is: "histrogram",
                            label: "Count",
                            data: this.shrinkValues,
                            backgroundColor: this.colors.primary,
                            barPercentage: 1,
                            categoryPercentage: 1,
                        },
                    ],
                };
            },

            chartOptions() {
                return {
                    interaction: {
                        mode: "index",
                        intersect: false,
                    },
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            display: false,
                        },
                        y: {
                            display: false,
                        },
                        xNorm: {
                            grid: {
                                tickColor: "white",
                            },
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 5,
                                callback: (value) => {
                                    const label = this.labels[value];
                                    const std =
                                        (label - this.data.mean) /
                                        this.data.std;

                                    return Math.round(std) === 0
                                        ? 0
                                        : Math.round(std) + "σ";
                                },
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            ...this.chartTooltip,
                            displayColors: true,
                            callbacks: {
                                title: (contexts) => {
                                    const label = contexts[0].label;

                                    const binLabel = this.data.binLabel
                                        ? `${this.data.binLabel}: `
                                        : "";

                                    const bin = this.formatNumber(
                                        label,
                                        this.format,
                                    );

                                    const from = this.formatNumber(
                                        +label - this.step / 2,
                                        this.format,
                                    );

                                    const to = this.formatNumber(
                                        +label + this.step / 2,
                                        this.format,
                                    );

                                    return `${binLabel}~${bin} (${from} - ${to})`;
                                },
                                label: (context) => {
                                    if (context.dataset.is !== "distribution") {
                                        return;
                                    }

                                    const label = context.dataset.label;
                                    const i = context.dataIndex;
                                    const value = this.shrinkProbability[i];
                                    const formatted =
                                        Math.round(value * 1000) / 10;

                                    return `${label}: ${formatted}%`;
                                },
                            },
                        },
                    },
                };
            },

            labelFormat() {
                return {
                    average: true,
                    mantissa: 1,
                    optionalMantissa: true,
                    trimMantissa: true,
                    roundingFunction: Math.floor,
                };
            },

            shouldShowChart() {
                return this.sufficientStd && this.sufficientPopulation;
            },

            sufficientStd() {
                return this.data.std > 0;
            },

            sufficientPopulation() {
                return this.data.population >= 30;
            },
        },

        methods: {
            /**
             * @link https://en.wikipedia.org/wiki/Error_function#Numerical_approximations
             * @link https://dlmf.nist.gov/7.2#i
             */
            erf(x) {
                // Abramowitz and Stegun constants
                const a1 = 0.254829592;
                const a2 = -0.284496736;
                const a3 = 1.421413741;
                const a4 = -1.453152027;
                const a5 = 1.061405429;
                const p = 0.3275911;

                const sign = x < 0 ? -1 : 1;
                x = Math.abs(x);

                // Abramowitz and Stegun formula
                const t1 = 1.0 / (1.0 + p * x);
                const t2 = Math.pow(t1, 2);
                const t3 = Math.pow(t1, 3);
                const t4 = Math.pow(t1, 4);
                const t5 = Math.pow(t1, 5);

                // prettier-ignore
                const y = 1.0 - (a1 * t1 + a2 * t2 + a3 * t3 + a4 * t4 + a5 * t5) * Math.exp(-x * x);

                return sign * y;
            },

            /**
             * @link https://en.wikipedia.org/wiki/Cumulative_distribution_function
             * @link https://dlmf.nist.gov/7.1
             */
            cdf(z) {
                return 0.5 * (1 + this.erf(z / Math.sqrt(2)));
            },

            /**
             * @link https://en.wikipedia.org/wiki/Standard_score
             */
            zValue(x) {
                return (x - this.data.mean) / this.data.std;
            },

            /**
             * @link https://en.wikipedia.org/wiki/Fundamental_theorem_of_calculus#Second_part
             * @link https://dlmf.nist.gov/1.4#Px15
             */
            normalArea(from, to) {
                const zFrom = this.zValue(from);
                const zTo = this.zValue(to);

                const cdfFrom = this.cdf(zFrom);
                const cdfTo = this.cdf(zTo);

                return cdfTo - cdfFrom;
            },
        },
    };
</script>
