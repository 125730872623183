<template>
    <div :class="`text-${field.textAlign}`">
        <template v-if="hasValue">
            <div
                v-if="!field.popover"
                class="leading-normal"
            >
                <component
                    :is="`index-${line.component}`"
                    v-for="line in field.lines"
                    :key="line.value"
                    :field="line"
                    :resource-name="resourceName"
                />
            </div>
            <popper
                v-else
                placement="top-start"
                trigger="hover"
                :trigger-class="{
                    'cursor-pointer': true,
                    'justify-end': field.textAlign === 'right',
                    'justify-center': field.textAlign === 'center',
                    'bg-black/5': open,
                }"
                @close="open = false"
                @open="open = true"
            >
                <template #trigger>
                    <component
                        :is="`index-${field.result.component}`"
                        class="underline decoration-dotted decoration-1 underline-offset-4"
                        :field="field.result"
                        :resource-name="resourceName"
                    />
                </template>
                <template #content>
                    <div
                        class="shadow md:rounded-lg min-w-[12rem] md:min-w-[24rem] max-w-2xl"
                    >
                        <div
                            class="bg-gray-700 text-white border border-b-0 border-gray-300 py-1 px-3 rounded-t-lg hidden md:flex gap-1"
                        >
                            <span v-text="field.fullName || field.name" />
                            <span
                                v-if="field.sublabel"
                                v-text="`(${field.sublabel})`"
                            />
                        </div>
                        <field-grid class="@container/peekable">
                            <component
                                :is="`detail-${line.component}`"
                                v-for="(line, index) in field.lines"
                                :key="index"
                                :field="line"
                                :index="index"
                                :resource-id="resourceId"
                                :resource-name="resourceName"
                            />
                        </field-grid>
                    </div>
                </template>
            </popper>
        </template>
        <p v-else>&mdash;</p>
    </div>
</template>

<script>
    export default {
        props: ["resourceId", "resourceName", "field"],

        data: () => ({
            open: false,
        }),

        computed: {
            hasValue() {
                return this.field.lines;
            },
        },
    };
</script>
