<template>
    <div
        :aria-disabled="disabled"
        :aria-label="label"
        class="checkbox group"
        :data-focus="!disabled && focused"
        :data-state="checkedState"
        :data-value="modelValue"
        role="checkbox"
        :tabindex="disabled ? -1 : 0"
        @blur="focused = false"
        @click="handleChange"
        @focus="focused = true"
        @keydown.space.prevent="handleChange"
    >
        <span
            class="checkbox-label"
            :class="{
                'opacity-50': disabled,
            }"
        >
            <icon
                class="checkbox-icon:checked"
                name="mdi-check-bold"
            />
            <icon
                class="checkbox-icon:indeterminate"
                name="mdi-minus-thick"
            />
        </span>

        <slot>
            {{ label }}
        </slot>
    </div>
</template>

<script>
    import Icon from "@nova/components/Icon.vue";

    export default {
        components: {
            Icon,
        },

        props: {
            modelValue: { type: Boolean, default: false },
            indeterminate: { type: Boolean, default: false },
            disabled: { type: Boolean, default: false },
            label: {},
        },

        emits: ["change", "update:modelValue"],

        data: () => ({
            focused: false,
        }),

        computed: {
            checkedState() {
                if (this.indeterminate) {
                    return "indeterminate";
                }

                return this.modelValue ? "checked" : "unchecked";
            },
        },

        methods: {
            handleChange(e) {
                if (this.disabled) {
                    return;
                }

                this.$emit("change", !this.modelValue);
                this.$emit("update:modelValue", !this.modelValue);
            },
        },
    };
</script>

<style>
    @layer nova {
        .checkbox {
            @apply inline-flex shrink-0 items-center gap-2 focus:outline-none select-none cursor-pointer;
        }

        .checkbox-label {
            @apply relative inline-flex items-center justify-center h-4 w-4;
            @apply border border-gray-500 ring-offset-2;
            @apply bg-white text-white;

            @apply group-data-[state=checked]:border-transparent group-data-[state=checked]:bg-blue-600;
            @apply group-data-[state=indeterminate]:border-transparent group-data-[state=indeterminate]:bg-blue-600;
            @apply group-data-[focus=true]:ring-2 group-data-[focus=true]:ring-blue-600;

            @apply dark:bg-gray-900 dark:border-white/30;
        }

        .checkbox-icon\:checked {
            @apply absolute inset-0 w-4 h-4 -mt-[0.3rem] -ml-px;
            @apply group-data-[state=checked]:opacity-100;
            @apply group-data-[state=indeterminate]:opacity-0;
            @apply group-data-[state=unchecked]:opacity-0;
        }

        .checkbox-icon\:indeterminate {
            @apply absolute inset-0 w-4 h-4 -mt-[0.3rem] -ml-px;
            @apply group-data-[state=checked]:opacity-0;
            @apply group-data-[state=indeterminate]:opacity-100;
            @apply group-data-[state=unchecked]:opacity-0;
        }
    }
</style>
