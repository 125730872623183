<template>
    <table-item :field="field">
        <template #value>
            {{ formattedDate }}
        </template>
    </table-item>
</template>

<script>
    export default {
        props: ["field"],

        computed: {
            formattedDate() {
                if (this.field.usesCustomizedDisplay) {
                    return this.field.displayedAs;
                }

                return dayjs(this.field.value).format(
                    App.config("dayjs.formats.date"),
                );
            },
        },
    };
</script>
