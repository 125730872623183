<template>
    <div class="relative">
        <button
            v-if="!disabled"
            class="absolute top-0 left-0 m-1.5 hover:cursor-pointer"
            title="Toggle"
            type="button"
            @click.stop="picker.toggle()"
        >
            <icon
                class="h-5 w-5"
                name="heroicon-s-calendar"
            />
        </button>

        <input
            v-bind="$attrs"
            ref="picker"
            class="form-control"
            :class="{
                'px-8': !disabled,
                'form-control-error': hasError,
            }"
            :disabled="disabled"
            type="date"
        />

        <button
            v-if="!disabled && hasValue"
            class="absolute top-0 right-0 m-1.5 hover:cursor-pointer"
            title="Clear"
            type="button"
            @click.stop="resetValue"
        >
            <icon
                class="h-5 w-5 text-red-500"
                name="heroicon-s-x-mark"
            />
        </button>
    </div>
</template>

<script>
    import Icon from "@nova/components/Icon.vue";
    import dayjs from "dayjs";
    import flatpickr from "flatpickr";

    export default {
        components: {
            Icon,
        },

        props: {
            value: [String, Array],

            disabled: {
                type: Boolean,
                default: false,
            },

            hasError: {
                type: Boolean,
                default: false,
            },

            pick: {
                type: String,
                default: "date",
            },

            /** @link https://flatpickr.js.org/options/ */
            options: {
                type: Object,
                default: () => ({}),
            },

            mode: {
                type: String,
                default: "single",
                validator: (v) => ["single", "multiple", "range"].includes(v),
            },

            min: String,
            max: String,
            step: String,
        },

        emits: ["change", "open", "close"],

        data: () => ({
            picker: null,
            emitValue: null,
        }),

        computed: {
            pickerValue() {
                return this.formatForPicker(this.value);
            },

            pickerOptions() {
                const attrs = this.options;

                return {
                    ...this.defaultOptions,
                    ...attrs,
                };
            },

            defaultOptions() {
                return {
                    mode: this.mode,
                    dateFormat: this.pickerFormat,
                    enableTime: this.pick === "datetime",
                    minDate: this.formatForPicker(this.min),
                    maxDate: this.formatForPicker(this.max),
                    minuteIncrement: this.step || 5,
                    onChange: (dates) => {
                        this.emitValue =
                            this.mode === "single"
                                ? this.formatForEmit(dates[0])
                                : dates.map((d) => this.formatForEmit(d));
                    },
                    onOpen: () => this.$emit("open"),
                    onClose: () => {
                        this.$nextTick(() => {
                            this.updateValue(this.emitValue);
                            this.$emit("close");
                        });
                    },
                };
            },

            pickerFormat() {
                return this.formats.picker;
            },

            dayjsToPickerFormat() {
                return this.formats.dayjsToPicker;
            },

            dayjsToEmitFormat() {
                return this.formats.dayjsToEmit;
            },

            formats() {
                if (this.pick === "datetime") {
                    return {
                        picker: App.config("flatpickr.formats.datetime"),
                        dayjsToPicker: App.config("dayjs.formats.datetime"),
                        dayjsToEmit: App.config(
                            "dayjs.server-formats.datetime",
                        ),
                    };
                }

                return {
                    picker: App.config("flatpickr.formats.date"),
                    dayjsToPicker: App.config("dayjs.formats.date"),
                    dayjsToEmit: App.config("dayjs.server-formats.date"),
                };
            },

            hasValue() {
                if (!this.value) {
                    return false;
                }

                if (Array.isArray(this.value)) {
                    return this.value.filter((v) => v).length > 0;
                }

                return true;
            },
        },

        watch: {
            pickerValue() {
                this.picker.setDate(this.pickerValue);
            },

            pickerOptions() {
                this.refreshPicker();
            },
        },

        mounted() {
            this.refreshPicker();
        },

        beforeUnmount() {
            this.picker.destroy();
        },

        methods: {
            refreshPicker() {
                if (this.picker) {
                    this.picker.destroy();
                }

                this.picker = flatpickr(this.$refs.picker, this.pickerOptions);

                if (this.value) {
                    this.picker.setDate(this.pickerValue);
                }
            },

            formatForPicker(value) {
                if (!value) {
                    return null;
                }

                if (Array.isArray(value)) {
                    return value.map((v) =>
                        this.coalesce(
                            dayjs(v).format(this.dayjsToPickerFormat),
                        ),
                    );
                }

                return this.coalesce(
                    dayjs(value).format(this.dayjsToPickerFormat),
                );
            },

            formatForEmit(value) {
                return value
                    ? dayjs(value).format(this.dayjsToEmitFormat)
                    : null;
            },

            updateValue(value) {
                this.$emit("change", value);
            },

            resetValue() {
                const initialValue = {
                    single: null,
                    multiple: [],
                    range: [null, null],
                }[this.mode];

                this.emitValue = initialValue;
                this.updateValue(this.emitValue);
            },

            coalesce(value) {
                return value === "Invalid Date" ? null : value;
            },
        },
    };
</script>
