/**
 * @see https://froala.com/wysiwyg-editor/docs/methods/
 */
const FroalaImport = () => import("froala-editor");
const FroalaPluginsImport = () =>
    import("froala-editor/js/plugins.pkgd.min.js");

// Import third party plugins
const ShowdownPluginImport = () =>
    import("froala-editor/js/third_party/showdown.min");
// const SpellCheckerPluginImport = () => import("froala-editor/js/third_party/spell_checker.min");

import { merge } from "lodash";
import { FileSelector, ImageSelector } from "../Plugins";

let FroalaEditor = null;

async function importFroalaEditor() {
    if (!FroalaEditor) {
        FroalaEditor = (await FroalaImport()).default;
        FroalaEditor.DefineIcon(FileSelector.name, FileSelector.icon);
        FroalaEditor.RegisterCommand(FileSelector.name, FileSelector.command);

        FroalaEditor.DefineIcon(ImageSelector.name, ImageSelector.icon);
        FroalaEditor.RegisterCommand(ImageSelector.name, ImageSelector.command);
        window.FroalaEditor = FroalaEditor;

        await FroalaPluginsImport();
        await ShowdownPluginImport();
        // await SpellCheckerPluginImport();
    }
}

class BaseEditor {
    editor = null;

    enterKey = null;

    name = null;

    static elementsMounted = [];

    constructor(element = "", options = {}) {
        this.element = element;
        this.options = options;

        if (this.element) {
            if (!document.querySelector(this.element)) {
                console.warn(
                    `Element provided [${this.element}] not found in DOM to mount Froala.
                    Must set element and call init manually`,
                );

                return;
            }

            this.init();
        }
    }

    async init() {
        if (this.checkForMountedElement()) {
            console.warn(
                `Element provided [${this.element}] already mounted with Froala. Ignoring...`,
            );

            return;
        }

        await this.loadFroalaEditor();

        if (!this.enterKey) {
            await this.setEnterKey("P");
        }

        this.editor = new FroalaEditor(
            document.querySelector(this.element),
            this.config(),
        );

        this.addSelfToInstances();
        BaseEditor.elementsMounted.push(this.element);
    }

    async loadFroalaEditor() {
        await importFroalaEditor();
    }

    setElement(element) {
        this.element = element;
    }

    setOptions(options) {
        this.options = options;
    }

    async setEnterKey(key) {
        if (!FroalaEditor) {
            await this.loadFroalaEditor();
        }

        switch (key.toUpperCase()) {
            case "P":
                this.enterKey = FroalaEditor.ENTER_P;
                break;
            case "BR":
                this.enterKey = FroalaEditor.ENTER_BR;
                break;
            case "DIV":
                this.enterKey = FroalaEditor.ENTER_DIV;
                break;
            default:
                this.enterKey = FroalaEditor.ENTER_P;
                console.warn(
                    `Invalid enter key provided: [${key}]. Defaulting to 'P'`,
                );
                break;
        }
    }

    setName(name) {
        this.name = name;
    }

    addSelfToInstances() {
        let name = (this.name || this.element)
            .replace(/[^a-zA-Z0-9]/g, "_")
            .replace(/^_+|_+$/g, "");
        TextEditorInstances.addInstance(this, name);
    }

    config() {
        const config = merge(this.defaultConfig(), this.options);

        if (config.imageUploadURL) {
            config.imageUpload = true;
            config.imagePaste = true;
        }

        if (config.fileUploadURL) {
            config.fileUpload = true;
        }

        return config;
    }

    /**
     * @see https://froala.com/wysiwyg-editor/docs/options/
     */
    defaultConfig() {
        return {
            toolbarButtons: this.toolbarButtons,
            key: window.froala?.key,
            enter: this.enterKey,
            attribution: false,
            quickInsertEnabled: false,
            requestHeaders: {
                "X-CSRF-Token": document.querySelector(
                    'meta[name="csrf-token"]',
                ).content,
            },
            spellcheck: true,
            theme: "gray",
            fileUpload: false,
            imageUpload: false,
            imagePaste: false,
            imageUploadParam: "upload",
            events: {
                "image.beforeUpload": function (images, imgElements) {
                    if (!imgElements) return;

                    images.forEach(async (image, index) => {
                        let imgBit = await createImageBitmap(image);
                        if (!imgBit) return;

                        imgElements[index].style.width = `${imgBit.width}px`;
                        imgElements[index].style.height = `${imgBit.height}px`;
                    });
                },
                "image.uploaded": function (response) {
                    response =
                        typeof response === "string"
                            ? JSON.parse(response)
                            : response;
                    document.dispatchEvent(
                        new CustomEvent("editor_image_uploaded", {
                            detail: response,
                        }),
                    );
                },
            },
            lineHeights: {
                Default: "",
                Single: "1",
                1.15: "1.15",
                1.5: "1.5",
                Double: "2",
            },
            colorsText: [
                "#61BD6D",
                "#1ABC9C",
                "#54ACD2",
                "#2C82C9",
                "#9365B8",
                "#475577",
                "#CCCCCC",
                "#41A85F",
                "#00A885",
                "#3D8EB9",
                "#2969B0",
                "#553982",
                "#28324E",
                "#000000",
                "#F7DA64",
                "#FBA026",
                "#EB6B56",
                "#E25041",
                "#A38F84",
                "#EFEFEF",
                "#FFFFFF",
                "#FAC51C",
                "#F37934",
                "#D14841",
                "#B8312F",
                "#7C706B",
                "#D1D5D8",
                "REMOVE",
            ],
            colorsBackground: [
                "#15E67F",
                "#E3DE8C",
                "#D8A076",
                "#D83762",
                "#76B6D8",
                "REMOVE",
                "#1C7A90",
                "#249CB8",
                "#4ABED9",
                "#FBD75B",
                "#FBE571",
                "#FFFFFF",
            ],
            colorsStep: 6,
            colorsButtons: ["colorsBack", "|", "-"],
            fontFamily: {
                "Roboto,sans-serif": "Roboto",
                "Arial,sans-serif": "Arial",
                "Georgia,serif": "Georgia",
                "Impact,Charcoal,sans-serif": "Impact",
                "Tahoma,Geneva,sans-serif": "Tahoma",
                "Times New Roman,Times,serif": "Times New Roman",
                "Verdana,Geneva,sans-serif": "Verdana",
            },
        };
    }

    disable() {
        this.editor.edit.off();
    }

    enable() {
        this.editor.edit.on();
    }

    getContent() {
        return this.editor.html.get();
    }

    setContent(content) {
        this.editor.html.set(content);
    }

    checkForMountedElement() {
        return BaseEditor.elementsMounted.includes(this.element);
    }
}

BaseEditor.prototype.toolbarButtons = [];

class TextEditorInstances {
    static #instances = {};

    static addInstance(instance, name) {
        TextEditorInstances.#instances[name] = instance;
    }

    static getInstance(name) {
        return TextEditorInstances.#instances[name];
    }
}

window.emsTextEditor = function (name) {
    return TextEditorInstances.getInstance(name);
};

export default BaseEditor;
