<template>
    <div>
        <input
            ref="fileInput"
            :accept="acceptedTypes"
            class="absolute overflow-hidden w-0 h-0 clip"
            :disabled="disabled"
            :dusk="$attrs['input-dusk']"
            :multiple="multiple"
            tabindex="-1"
            type="file"
            @change.prevent="handleChange"
        />

        <div class="space-y-4">
            <div
                v-if="files.length > 0"
                class="grid grid-cols-4 gap-x-6 gap-y-2"
            >
                <file-preview-block
                    v-for="(file, index) in files"
                    :key="index"
                    :dusk="$attrs.dusk"
                    :file="file"
                    :rounded="rounded"
                    @removed="() => handleRemove(index)"
                />
            </div>

            <div
                class="focus:outline-none focus:!border-primary-500 block cursor-pointer p-4 bg-gray-50 dark:bg-gray-900 dark:hover:bg-gray-900 border-4 border-dashed hover:border-gray-300 dark:border-gray-700 dark:hover:border-gray-600 rounded-lg"
                :class="{ 'border-gray-300 dark:border-gray-600': startedDrag }"
                role="button"
                tabindex="0"
                @click="handleClick"
                @dragenter.prevent="handleOnDragEnter"
                @dragleave.prevent="handleOnDragLeave"
                @dragover.prevent
                @drop.prevent="handleOnDrop"
                @keydown.enter.prevent="handleClick"
                @keydown.space.prevent="handleClick"
            >
                <div class="flex items-center space-x-4 pointer-events-none">
                    <p class="text-center pointer-events-none">
                        <v-button as="div">
                            {{
                                multiple
                                    ? __("Choose Files")
                                    : __("Choose File")
                            }}
                        </v-button>
                    </p>

                    <p
                        class="pointer-events-none text-center text-sm text-gray-500 dark:text-gray-400 font-semibold"
                    >
                        {{
                            multiple
                                ? __("Drop files or click to choose")
                                : __("Drop file or click to choose")
                        }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import VButton from "@nova/components/Buttons/VButton.vue";
    import FilePreviewBlock from "@nova/components/Files/FilePreviewBlock.vue";

    export default {
        name: "DropZone",

        components: {
            VButton,
            FilePreviewBlock,
        },

        props: {
            files: { type: Array, default: () => [] },
            multiple: { type: Boolean, default: false },
            rounded: { type: Boolean, default: false },
            acceptedTypes: { type: String, default: null },
            disabled: { type: Boolean, default: false },
        },

        emits: ["fileChanged", "fileRemoved"],

        data: () => ({
            startedDrag: false,
            demFiles: [],
        }),

        methods: {
            handleClick() {
                this.$refs.fileInput.click();
            },

            handleChange() {
                this.demFiles = this.multiple
                    ? this.$refs.fileInput.files
                    : [this.$refs.fileInput.files[0]];

                this.$emit("fileChanged", this.demFiles);
                this.$refs.fileInput.files = null;
            },

            handleRemove(index) {
                this.$emit("fileRemoved", index);
                this.$refs.fileInput.files = null;
                this.$refs.fileInput.value = null;
            },

            handleOnDragEnter() {
                this.startedDrag = true;
            },

            handleOnDragLeave() {
                this.startedDrag = false;
            },

            handleOnDrop(e) {
                this.demFiles = this.multiple
                    ? e.dataTransfer.files
                    : [e.dataTransfer.files[0]];

                this.$emit("fileChanged", this.demFiles);
            },
        },
    };
</script>

<style></style>
