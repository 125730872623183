<template>
    <div class="flex flex-col gap-2">
        <div
            v-if="title || suffix"
            class="flex justify-between"
        >
            <h4
                v-if="title"
                class="m-0 p-0"
                v-text="title"
            />
            <h4
                v-if="suffix"
                class="m-0 p-0"
                v-text="suffix"
            />
        </div>
        <div class="flex-1">
            <canvas ref="chart"></canvas>
        </div>
        <help-tooltips>
            <help-range-tooltip :range="range" />
            <slot name="help" />
        </help-tooltips>
    </div>
</template>

<script>
    import { formatNumber } from "@nova/utils";
    import BaseRangedChartMetric from "./BaseRangedChartMetric.vue";
    import HelpTooltips from "@nova/components/HelpTooltips.vue";
    import HelpRangeTooltip from "@nova/components/HelpRangeTooltip.vue";

    export default {
        components: {
            HelpTooltips,
            HelpRangeTooltip,
        },

        extends: BaseRangedChartMetric,

        computed: {
            comparative() {
                return !!this.data.ranges;
            },

            items() {
                if (!this.data.partition) {
                    return [];
                }

                const partition = Object.values(this.data.partition);

                return _(partition)
                    .map((item) => ({
                        ...item,
                        value: item.current || item.value,
                    }))
                    .orderBy("value", ["desc"])
                    .value();
            },

            chartType() {
                return "bar";
            },

            chartData() {
                if (this.items.length === 0) {
                    return {};
                }

                const items = this.items;

                const total =
                    this.data.total || items.reduce((c, i) => c + i.value, 0);

                return {
                    labels: items.map((i) => i.label),
                    datasets: [
                        {
                            data: items.map((i) => i.value / total),
                            items: items,
                            backgroundColor: this.colors.primary,
                            categoryPercentage: 1,
                            barPercentage: 0.1,
                        },
                    ],
                };
            },

            chartOptions() {
                return {
                    indexAxis: "y",
                    interaction: {
                        mode: "index",
                        axis: "y",
                        intersect: false,
                    },
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            display: false,
                            max: 1,
                        },
                        y: {
                            display: false,
                        },
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            ...this.chartTooltip,
                            callbacks: {
                                title: () => {
                                    if (!this.comparative) {
                                        return null;
                                    }

                                    const current = this.data.ranges.current;
                                    const previous = this.data.ranges.previous;

                                    const formattedCurrent =
                                        current.from === current.to
                                            ? current.from
                                            : `${current.from} - ${current.to}`;

                                    const formattedPrevious =
                                        previous.from === previous.to
                                            ? previous.from
                                            : `${previous.from} - ${previous.to}`;

                                    return `${formattedCurrent} vs ${formattedPrevious}`;
                                },
                                label: (context) => {
                                    const suffix = this.suffix
                                        ? this.suffix + ": "
                                        : "";

                                    const percentage =
                                        Math.round(context.raw * 1000) / 10 +
                                        "%";
                                    const value = formatNumber(
                                        this.items[context.dataIndex].value,
                                        this.format,
                                    );

                                    return `${suffix}${value} (${percentage} of Total)`;
                                },
                                footer: (contexts) => {
                                    if (!this.comparative) {
                                        return null;
                                    }

                                    const i = contexts[0].dataIndex;

                                    const item = this.items[i];
                                    const current = item.current;
                                    const previous = item.previous;

                                    const ratio = this.getChangeRatio(
                                        current,
                                        previous,
                                    );

                                    if (ratio === 0) {
                                        return "No Change";
                                    }

                                    if (ratio === null) {
                                        return "No Prior Data";
                                    }

                                    const percent = formatNumber(
                                        Math.abs(ratio * 100),
                                        {
                                            mantissa: 1,
                                        },
                                    );

                                    const label =
                                        ratio > 0 ? "Increase" : "Decrease";

                                    return `${percent}% ${label}`;
                                },
                            },
                        },
                        barLabels: {
                            color: this.colors.text,
                        },
                        backgroundBar: {
                            color: this.colors.grid,
                        },
                    },
                };
            },

            chartPlugins() {
                return [
                    {
                        id: "backgroundBar",
                        beforeDatasetsDraw: (chart, args, pluginOptions) => {
                            const {
                                data,
                                ctx,
                                chartArea: { width, height },
                                scales: { y },
                            } = chart;

                            const dataset = data.datasets[0];

                            if (!dataset) {
                                return;
                            }

                            const segment = height / data.labels.length;
                            const barHeight =
                                segment *
                                dataset.barPercentage *
                                dataset.categoryPercentage;

                            ctx.fillStyle = pluginOptions.color;

                            for (let i = 0; i < data.labels.length; i++) {
                                ctx.fillRect(
                                    0,
                                    y.getPixelForValue(i) - barHeight / 2,
                                    width,
                                    barHeight,
                                );
                            }
                        },
                    },
                    {
                        id: "barLabels",
                        beforeDatasetsDraw: (chart, args, pluginOptions) => {
                            const ctx = chart.ctx;

                            const dataset = chart.data.datasets[0];
                            const meta = chart.getDatasetMeta(0);

                            if (meta.hidden) {
                                return;
                            }

                            meta.data.forEach((element, index) => {
                                ctx.fillStyle = pluginOptions.color;

                                const left = element.base;
                                const right = chart.chartArea.right;

                                const height = chart.chartArea.height;
                                const segment =
                                    height / chart.data.labels.length;
                                const barHeight =
                                    segment *
                                    dataset.barPercentage *
                                    dataset.categoryPercentage;

                                const y = element.y - barHeight / 2 - 4;

                                const label = chart.data.labels[index];
                                ctx.textAlign = "left";
                                ctx.fillText(label, left, y);

                                ctx.textAlign = "right";

                                if (!this.comparative) {
                                    const value = this.formatNumber(
                                        this.items[index].value,
                                        this.format,
                                    );

                                    ctx.fillText(value, right, y);
                                    return;
                                }

                                const current = this.items[index].current;
                                const previous = this.items[index].previous;

                                const value = formatNumber(
                                    current,
                                    this.format,
                                );

                                const ratio = this.getChangeRatio(
                                    current,
                                    previous,
                                );
                                const sign = Math.sign(ratio);
                                const percent = this.getDeltaPercentage(ratio);
                                const color = this.getDeltaColor(sign);
                                const textWidth = ctx.measureText(
                                    percent + "%",
                                ).width;

                                ctx.fillText(value, right - 80, y);

                                ctx.fillStyle = color;

                                const ix =
                                    right - textWidth - 2 - (!ratio ? 8 : 0);
                                const iy = y - 12 - (!ratio ? 2 : 0);

                                this.drawDeltaIcon(ctx, sign, ix, iy);
                                ctx.fillText(percent + "%", right, y);
                            });
                        },
                    },
                ];
            },
        },
    };
</script>
