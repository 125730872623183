<template>
    <div class="flex flex-col gap-2">
        <h4
            v-if="title"
            class="m-0 p-0"
            v-text="title"
        />

        <div class="flex items-center gap-4">
            <span
                class="text-3xl"
                v-text="formattedValue"
            ></span>
            <span
                class="flex items-center gap-1 text-xs"
                :style="`color: ${deltaColor};`"
            >
                <icon
                    class="!w-4 !h-4"
                    :name="deltaIcon"
                />
                <span
                    v-if="changeRatio !== null"
                    v-text="deltaPercentage + '%'"
                ></span>
                <span v-else> No Prior Data </span>
            </span>
        </div>
    </div>
</template>

<script>
    import BaseMetric from "./BaseMetric.vue";
    import Icon from "@nova/components/Icon.vue";

    export default {
        components: {
            Icon,
        },

        extends: BaseMetric,

        computed: {
            deltaColor() {
                return this.getDeltaColor(this.increaseOrDecrease);
            },

            deltaIcon() {
                return this.getDeltaIcon(this.increaseOrDecrease);
            },

            deltaPercentage() {
                return this.getDeltaPercentage(this.changeRatio);
            },

            increaseOrDecrease() {
                return this.getIncreaseOrDecrease(this.changeRatio);
            },

            changeRatio() {
                return this.getChangeRatio(
                    this.data?.current,
                    this.data?.previous,
                );
            },

            formattedValue() {
                if (this.data?.current == null) {
                    return "";
                }

                return this.formatNumber(
                    this.data.current,
                    this.format || {
                        average: true,
                        mantissa: 2,
                        optionalMantissa: true,
                    },
                );
            },
        },
    };
</script>
