<template>
    <table-item :field="field">
        <template #value="{ item }">
            <a
                class="link-default whitespace-nowrap"
                :href="`mailto:${item.field.value}`"
                @click.stop
            >
                {{ item.fieldValue }}
            </a>
        </template>
    </table-item>
</template>

<script>
    export default {
        props: ["field"],
    };
</script>
