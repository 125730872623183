<template>
    <label class="form-label">
        <slot>
            <span>{{ label }}</span>
        </slot>
        <span
            v-if="sublabel"
            class="text-sm font-normal text-gray-700 dark:text-gray-200"
            v-text="`(${sublabel})`"
        />
        <span
            v-if="required"
            class="text-red-500 text-sm"
            v-text="'*'"
        />
    </label>
</template>

<script>
    export default {
        props: {
            label: String,

            required: {
                type: Boolean,
                default: false,
            },

            sublabel: String,
        },
    };
</script>
