<script>
    import Field from "./Field.vue";

    export default {
        extends: Field,
        props: ["resource", "resourceName"],

        computed: {
            alignClass() {
                if (this.field.textAlign === "center") {
                    return "mx-auto";
                }

                if (this.field.textAlign === "right") {
                    return "ml-auto";
                }

                return "";
            },

            justifyClass() {
                if (this.field.textAlign === "center") {
                    return "justify-center";
                }

                if (this.field.textAlign === "right") {
                    return "justify-end";
                }

                return "justify-start";
            },
        },
    };
</script>
