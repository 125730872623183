<template>
    <loading-view
        :class="{
            'mx-4': !isRelation,
        }"
        :data-relationship="viaRelationship"
        :data-resource="resourceName"
        :dusk="`${resourceName}-index-component`"
        :loading="initialLoading"
    >
        <template v-if="shouldOverrideMeta && resourceInformation">
            <app-head
                :section="resourceInformation.group"
                :title="resourceInformation.label"
            />

            <teleport to="#page-title">
                {{ resourceInformation.label }}
            </teleport>
        </template>

        <div
            v-else
            class="flex items-center justify-between mb-2"
        >
            <heading
                class="flex items-center"
                dusk="index-heading"
                :level="2"
            >
                {{ headingTitle }}
            </heading>

            <div :id="`${resourceName}-panel-actions`" />
        </div>

        <teleport
            :to="
                shouldOverrideMeta
                    ? '#page-actions'
                    : `#${resourceName}-panel-actions`
            "
        >
            <div
                class="flex flex-col-reverse lg:flex-row lg:items-center gap-2 lg:divide-x lg:divide-gray-300"
            >
                <div
                    v-if="availableStandaloneRunActions.length > 0"
                    class="ml-auto"
                >
                    <div class="flex flex-wrap items-center gap-2">
                        <action-buttons
                            :actions="availableStandaloneRunActions"
                            :relationship-type="relationshipType"
                            :resource-name="resourceName"
                            :selected-resources="
                                selectedResourcesForActionSelector
                            "
                            :via-relationship="viaRelationship"
                            :via-resource="viaResource"
                            :via-resource-id="viaResourceId"
                            @action-executed="handleActionExecuted"
                        />
                    </div>
                </div>
                <button-group class="lg:pl-2 first:pl-0">
                    <action-buttons
                        v-if="
                            availableStandaloneLinkActions.length > 0 ||
                            shouldShowCreateButton
                        "
                        :actions="availableStandaloneLinkActions"
                        :relationship-type="relationshipType"
                        :resource-name="resourceName"
                        :selected-resources="selectedResourcesForActionSelector"
                        :via-relationship="viaRelationship"
                        :via-resource="viaResource"
                        :via-resource-id="viaResourceId"
                        @action-executed="handleActionExecuted"
                    >
                        <!-- Create Link -->
                        <create-resource-button
                            :authorized-to-create="authorizedToCreate"
                            :authorized-to-relate="authorizedToRelate"
                            :relationship-type="relationshipType"
                            :resource-information="resourceInformation"
                            :resource-label="resourceInformation.singularLabel"
                            :resource-name="resourceName"
                            :via-relationship="viaRelationship"
                            :via-resource="viaResource"
                            :via-resource-id="viaResourceId"
                        />
                    </action-buttons>
                </button-group>
            </div>
        </teleport>

        <template v-if="!shouldBeCollapsed">
            <card class="grid grid-cols-6">
                <div
                    v-show="filterDockLeft"
                    class="pr-4 border-r mr-4"
                >
                    <field-grid
                        data-dock="left"
                        stacked
                    />
                </div>

                <div
                    :class="{
                        'col-span-5': filterDockLeft,
                        'col-span-6': !filterDockLeft,
                    }"
                >
                    <field-grid
                        v-show="filterDockTop"
                        class="mb-4"
                        data-dock="top"
                        stacked
                    />
                    <resource-table-toolbar
                        :lenses="lenses"
                        @action-executed="getResources"
                        @clear-selected-filters="
                            clearSelectedFilters(lens || null)
                        "
                        @close-delete-modal="closeDeleteModal"
                        @delete-all-matching="deleteAllMatchingResources"
                        @delete-selected="deleteSelectedResources"
                        @deselect-all="clearResourceSelections"
                        @filter-changed="filterChanged"
                        @filter-dock-changed="filterDockChanged"
                        @force-delete-all-matching="
                            forceDeleteAllMatchingResources
                        "
                        @force-delete-selected="forceDeleteSelectedResources"
                        @per-page-changed="updatePerPageChanged"
                        @polling-toggled="togglePolling"
                        @restore-all-matching="restoreAllMatchingResources"
                        @restore-selected="restoreSelectedResources"
                        @search="search = $event"
                        @select-all="toggleSelectAll"
                        @select-all-matching="toggleSelectAllMatching"
                        @trashed-changed="trashedChanged"
                    />

                    <loading-view
                        :loading="loading"
                        :variant="!resourceResponse ? 'default' : 'overlay'"
                    >
                        <index-error-dialog
                            v-if="resourceResponseError != null"
                            :resource="resourceInformation"
                            @click="getResources"
                        />
                        <index-empty-dialog
                            v-else-if="!resources.length"
                            :authorized-to-create="authorizedToCreate"
                            :authorized-to-relate="authorizedToRelate"
                            :create-button-label="createButtonLabel"
                            :relationship-type="relationshipType"
                            :resource-information="resourceInformation"
                            :resource-label="resourceInformation?.label"
                            :resource-name="resourceName"
                            :via-relationship="viaRelationship"
                            :via-resource="viaResource"
                            :via-resource-id="viaResourceId"
                        />
                        <template v-else>
                            <resource-table
                                ref="resourceTable"
                                :actions-are-available="allActions.length > 0"
                                :authorized-to-relate="authorizedToRelate"
                                class="mb-2"
                                :resources="resources"
                                :selected-resource-ids="selectedResourceIds"
                                :should-show-actions="
                                    resourceInformation.shouldShowActions
                                "
                                :singular-name="singularName"
                                :sortable="sortable"
                                :update-selection-status="updateSelectionStatus"
                                @action-executed="handleActionExecuted"
                                @delete="deleteResources"
                                @order="orderByField"
                                @reset-order-by="resetOrderBy"
                                @restore="restoreResources"
                            />

                            <resource-pagination
                                v-if="shouldShowPagination"
                                :all-matching-resource-count="
                                    allMatchingResourceCount
                                "
                                :current-page="currentPage"
                                :current-resource-count="currentResourceCount"
                                :has-next-page="hasNextPage"
                                :has-previous-page="hasPreviousPage"
                                :load-more="loadMore"
                                :loading-more="loadingMore"
                                :pagination-component="paginationComponent"
                                :per-page="perPage"
                                :resource-count-label="resourceCountLabel"
                                :select-page="selectPage"
                                :total-pages="totalPages"
                            />
                        </template>
                    </loading-view>
                </div>
            </card>
        </template>
    </loading-view>
</template>

<script>
    import ActionButtons from "@nova/components/ActionButtons.vue";
    import AppHead from "@nova/components/AppHead.vue";
    import ButtonGroup from "@nova/components/Buttons/ButtonGroup.vue";
    import Card from "@nova/components/Card.vue";
    import CreateResourceButton from "@nova/components/Buttons/CreateResourceButton.vue";
    import FieldGrid from "@nova/components/FieldGrid.vue";
    import Heading from "@nova/components/Heading.vue";
    import IndexEmptyDialog from "@nova/components/IndexEmptyDialog.vue";
    import IndexErrorDialog from "@nova/components/IndexErrorDialog.vue";
    import LoadingView from "@nova/components/LoadingView.vue";
    import ResourceListingPage from "@nova/extendables/ResourceListingPage.vue";
    import ResourcePagination from "@nova/components/Pagination/ResourcePagination.vue";
    import ResourceTable from "@nova/components/ResourceTable.vue";
    import ResourceTableToolbar from "@nova/components/ResourceTableToolbar.vue";
    import { CancelToken, isCancel } from "axios";
    import { mapActions } from "vuex";

    export default {
        components: {
            ActionButtons,
            AppHead,
            ButtonGroup,
            Card,
            CreateResourceButton,
            FieldGrid,
            Heading,
            IndexEmptyDialog,
            IndexErrorDialog,
            LoadingView,
            ResourcePagination,
            ResourceTable,
            ResourceTableToolbar,
        },

        extends: ResourceListingPage,

        data: () => ({
            collapsed: false,
            lenses: [],
        }),

        computed: {
            shouldBeCollapsed() {
                return this.collapsed && this.viaRelationship != null;
            },

            collapsedByDefault() {
                return this.field?.collapsedByDefault ?? false;
            },

            cardsEndpoint() {
                return `/nova-api/${this.resourceName}/cards`;
            },

            headingTitle() {
                if (this.initialLoading) {
                    return "&nbsp;";
                } else {
                    if (this.isRelation && this.field) {
                        return this.field.name;
                    } else {
                        if (this.resourceResponse !== null) {
                            return this.resourceResponse.label;
                        } else {
                            return this.resourceInformation.label;
                        }
                    }
                }
            },

            localStorageKey() {
                let name = this.resourceName;

                if (this.viaRelationship) {
                    name = `${name}.${this.viaRelationship}`;
                }

                return `nova.resources.${name}.collapsed`;
            },

            shouldShowCreateButton() {
                return (
                    this.authorizedToRelate &&
                    (this.authorizedToCreate ||
                        this.relationshipType === "belongsToMany" ||
                        this.relationshipType === "morphToMany")
                );
            },
        },

        async created() {
            if (!this.resourceInformation) {
                return;
            }

            // Bind the keydown event listener when the router is visited if this
            // component is not a relation on a Detail page
            if (this.shouldEnableShortcut === true) {
                Nova.addShortcut("c", this.handleKeydown);
                Nova.addShortcut("mod+a", this.toggleSelectAll);
                Nova.addShortcut("mod+shift+a", this.toggleSelectAllMatching);
            }

            Nova.$on("resources-detached", this.getAuthorizationToRelate);
        },

        beforeUnmount() {
            if (this.shouldEnableShortcut) {
                Nova.disableShortcut("c");
                Nova.disableShortcut("mod+a");
                Nova.disableShortcut("mod+shift+a");
            }

            Nova.$off("resources-detached", this.getAuthorizationToRelate);

            if (this.actionCanceller !== null) {
                this.actionCanceller();
            }
        },

        methods: {
            ...mapActions(["fetchPolicies"]),

            /**
             * Handle the keydown event
             */
            handleKeydown(e) {
                // `c`
                if (
                    this.authorizedToCreate &&
                    e.target.tagName !== "INPUT" &&
                    e.target.tagName !== "TEXTAREA" &&
                    e.target.contentEditable !== "true"
                ) {
                    Nova.visit(`/resources/${this.resourceName}/new`);
                }
            },

            /**
             * Get the resources based on the current page, search, filters, etc.
             */
            getResources() {
                if (this.shouldBeCollapsed) {
                    this.loading = false;
                    return;
                }

                this.loading = true;
                this.resourceResponseError = null;

                this.$nextTick(() => {
                    this.clearResourceSelections();

                    if (!this.initialLoading) {
                        Nova.$progress.start();
                    }

                    return this.$http
                        .get("/nova-api/" + this.resourceName, {
                            params: this.resourceRequestQueryString,
                            cancelToken: new CancelToken((canceller) => {
                                this.canceller = canceller;
                            }),
                        })
                        .then(({ data }) => {
                            this.resources = [];

                            this.resourceResponse = data;
                            this.resources = data.resources;
                            this.softDeletes = data.softDeletes;
                            this.perPage = data.per_page;
                            this.sortable = data.sortable;

                            this.handleResourcesLoaded();
                        })
                        .catch((e) => {
                            if (isCancel(e)) {
                                return;
                            }

                            this.loading = false;
                            this.resourceResponseError = e;

                            throw e;
                        })
                        .finally(() => {
                            Nova.$progress.done();
                        });
                });
            },

            /**
             * Get the relatable authorization status for the resource.
             */
            getAuthorizationToRelate() {
                if (
                    this.shouldBeCollapsed ||
                    (!this.authorizedToCreate &&
                        this.relationshipType !== "belongsToMany" &&
                        this.relationshipType !== "morphToMany")
                ) {
                    return;
                }

                if (!this.viaResource) {
                    return (this.authorizedToRelate = true);
                }

                return Nova.request()
                    .get(
                        "/nova-api/" +
                            this.resourceName +
                            "/relate-authorization" +
                            "?viaResource=" +
                            this.viaResource +
                            "&viaResourceId=" +
                            this.viaResourceId +
                            "&viaRelationship=" +
                            this.viaRelationship +
                            "&relationshipType=" +
                            this.relationshipType,
                    )
                    .then((response) => {
                        this.authorizedToRelate = response.data.authorized;
                    });
            },

            /**
             * Get the lenses available for the current resource.
             */
            getLenses() {
                this.lenses = [];

                if (this.viaResource) {
                    return;
                }

                return Nova.request()
                    .get("/nova-api/" + this.resourceName + "/lenses")
                    .then((response) => {
                        this.lenses = response.data;
                    });
            },

            /**
             * Get the actions available for the current resource.
             */
            getActions() {
                if (this.actionCanceller !== null) {
                    this.actionCanceller();
                }

                this.actions = [];
                this.pivotActions = null;

                if (this.shouldBeCollapsed) {
                    return;
                }

                return Nova.request()
                    .get(`/nova-api/${this.resourceName}/actions`, {
                        params: {
                            viaResource: this.viaResource,
                            viaResourceId: this.viaResourceId,
                            viaRelationship: this.viaRelationship,
                            relationshipType: this.relationshipType,
                            display: "index",
                            resources: this.selectAllMatchingChecked
                                ? "all"
                                : this.selectedResourceIds,
                            pivots: !this.selectAllMatchingChecked
                                ? this.selectedPivotIds
                                : null,
                        },
                        cancelToken: new CancelToken((canceller) => {
                            this.actionCanceller = canceller;
                        }),
                    })
                    .then((response) => {
                        this.actions = response.data.actions;
                        this.pivotActions = response.data.pivotActions;
                        this.resourceHasActions =
                            response.data.counts.resource > 0;
                    })
                    .catch((e) => {
                        if (isCancel(e)) {
                            return;
                        }

                        throw e;
                    });
            },

            /**
             * Get the count of all of the matching resources.
             */
            getAllMatchingResourceCount() {
                Nova.request()
                    .get("/nova-api/" + this.resourceName + "/count", {
                        params: this.resourceRequestQueryString,
                    })
                    .then((response) => {
                        this.allMatchingResourceCount = response.data.count;
                    });
            },

            /**
             * Load more resources.
             */
            loadMore() {
                this.loadingMore = true;

                if (this.currentPageLoadMore === null) {
                    this.currentPageLoadMore = this.currentPage;
                }

                this.currentPageLoadMore = this.currentPageLoadMore + 1;

                return this.$http
                    .get("/nova-api/" + this.resourceName, {
                        params: {
                            ...this.resourceRequestQueryString,
                            page: this.currentPageLoadMore, // We do this to override whatever page number is in the URL
                        },
                    })
                    .then(({ data }) => {
                        this.resourceResponse = data;
                        this.resources = [...this.resources, ...data.resources];

                        if (data.total !== null) {
                            this.allMatchingResourceCount = data.total;
                        } else {
                            this.getAllMatchingResourceCount();
                        }

                        Nova.$emit("resources-loaded", {
                            resourceName: this.resourceName,
                            mode: this.isRelation ? "related" : "index",
                        });
                    })
                    .finally(() => {
                        this.loadingMore = false;
                    });
            },

            async handleCollapsableChange() {
                this.loading = true;

                this.toggleCollapse();

                if (!this.collapsed) {
                    if (!this.filterHasLoaded) {
                        await this.initializeFilters(null);
                        if (!this.hasFilters) {
                            await this.getResources();
                        }
                    } else {
                        await this.getResources();
                    }

                    await this.getAuthorizationToRelate();
                    // await this.getActions();
                    this.restartPolling();
                } else {
                    this.loading = false;
                }
            },
        },
    };
</script>
