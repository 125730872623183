import BaseEditor from "./BaseEditor";

class Simple extends BaseEditor {}

Simple.prototype.toolbarButtons = [
    "bold",
    "italic",
    "underline",
    "|",
    "clearFormatting",
    "fontSize",
    "fontFamily",
    "textColor",
    "backgroundColor",
    "|",
    "formatOL",
    "formatUL",
    "|",
    "align",
    "|",
    "insertLink",
];

export default Simple;
