<template>
    <FieldLayout>
        <TextEditor
            v-bind="inputAttrs"
            v-model="value"
            ref="editor"
        />
    </FieldLayout>
</template>

<script>
    import BaseField from "@eagle-metal/ui/BaseField";
    import TextEditor from "@js/vue/components/Form/Inputs/TextEditor.vue";

    export default {
        name: "TextEditorField",
        components: {
            TextEditor,
        },
        extends: BaseField,
    };
</script>
