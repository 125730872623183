<template>
    <div class="flex flex-col gap-2">
        <h4
            v-if="title"
            class="m-0 p-0"
            v-text="title"
        />
        <div class="flex-1 relative">
            <canvas ref="chart"></canvas>
            <div
                class="absolute flex flex-col gap-4"
                :style="{
                    right: `${boxRight}px`,
                    bottom: `${boxBottom}px`,
                }"
            >
                <div class="border px-3 py-2 bg-white text-sm text-center">
                    <div class="font-bold">Trendline Intersection</div>
                    <div
                        v-if="!intersection"
                        class="text-red-500"
                    >
                        Balloon<br />
                        <span class="text-xs italic">
                            (Trendlines do not intersect)
                        </span>
                    </div>
                    <span v-else>{{ formattedIntersection }}</span>
                </div>

                <div class="border px-3 py-2 bg-white text-sm text-center">
                    <div class="font-bold">Flatline Intersection</div>
                    <div
                        v-if="!flatlineIntersection"
                        class="text-red-500"
                    >
                        Stagnant<br />
                        <span class="text-xs italic">
                            ({{ currentLabel }} is not increasing)
                        </span>
                    </div>
                    <span v-else>{{ formattedFlatline }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BaseChartMetric from "./BaseChartMetric.vue";

    export default {
        extends: BaseChartMetric,

        data: () => ({
            boxRight: 0,
            boxBottom: 0,
        }),

        computed: {
            currentLabel() {
                return this.data.currentLabel || "Current";
            },

            totalLabel() {
                return this.data.totalLabel || "Total";
            },

            currents() {
                return Object.values(this.data.trend).map((v) => v?.current);
            },

            totals() {
                return Object.values(this.data.trend).map((v) => v?.total);
            },

            currentLength() {
                return this.currents.filter(
                    (v) => v !== null && v !== undefined,
                ).length;
            },

            currentTrendline() {
                return this.trendline(this.currents);
            },

            totalTrendline() {
                return this.trendline(this.totals);
            },

            totalFlatline() {
                return this.flatline(this.totals);
            },

            intersection() {
                if (!this.data.trend) {
                    return null;
                }

                const current = this.currentTrendline;
                const total = this.totalTrendline;

                if (current.slope <= total.slope) {
                    return null;
                }

                return (
                    (total.intercept - current.intercept) /
                    (current.slope - total.slope)
                );
            },

            flatlineIntersection() {
                if (!this.data.trend) {
                    return null;
                }

                const current = this.currentTrendline;
                const flatline = this.totalFlatline;

                if (current.slope === 0) {
                    return null;
                }

                return (flatline.intercept - current.intercept) / current.slope;
            },

            formattedIntersection() {
                return this.formatNumber(
                    this.intersection - this.currentLength,
                    {
                        output: "time",
                        unit: this.data.intersectionUnit || "days",
                    },
                );
            },

            formattedFlatline() {
                return this.formatNumber(
                    this.flatlineIntersection - this.currentLength,
                    {
                        output: "time",
                        unit: this.data.intersectionUnit || "days",
                    },
                );
            },

            chartType() {
                return "bar";
            },

            chartData() {
                if (!this.data?.trend) {
                    return {};
                }

                return {
                    labels: Object.keys(this.data.trend),
                    datasets: [
                        {
                            is: "current",
                            label: this.currentLabel,
                            data: this.currents,
                            backgroundColor: this.colors.primary,
                            categoryPercentage: 1,
                            barPercentage: 0.6,
                        },
                        {
                            is: "total",
                            label: this.totalLabel,
                            data: this.totals,
                            backgroundColor: this.colors.secondary,
                            categoryPercentage: 1,
                            barPercentage: 0.6,
                        },
                        {
                            is: "current-trendline",
                            label: this.currentLabel + " (Trendline)",
                            data: this.currentTrendline.series,
                            trendline: this.currentTrendline,
                            borderColor: this.colors.primary,
                            backgroundColor: "white",
                            borderWidth: 1,
                            borderDash: [5, 5],
                            pointRadius: 0,
                            pointHoverRadius: 3,
                            type: "line",
                        },
                        {
                            is: "total-trendline",
                            label: this.totalLabel + " (Trendline)",
                            data: this.totalTrendline.series,
                            trendline: this.totalTrendline,
                            borderColor: this.colors.secondary,
                            backgroundColor: "white",
                            borderWidth: 1,
                            borderDash: [5, 5],
                            pointRadius: 0,
                            pointHoverRadius: 3,
                            type: "line",
                        },
                        {
                            is: "flatline",
                            label: this.totalLabel + " (Flatline)",
                            data: this.totalFlatline.series,
                            borderColor: this.colors.grid,
                            backgroundColor: "white",
                            borderWidth: 1,
                            borderDash: [5, 5],
                            pointRadius: 0,
                            pointHoverRadius: 3,
                            type: "line",
                        },
                    ],
                };
            },

            chartOptions() {
                return {
                    interaction: {
                        mode: "index",
                        intersect: false,
                    },
                    scales: {
                        x: {
                            stacked: true,
                            grid: {
                                display: false,
                            },
                        },
                        y: {
                            grid: {
                                tickColor: "white",
                            },
                            border: {
                                display: false,
                            },
                            position: "right",
                        },
                    },
                    plugins: {
                        legend: {
                            position: "bottom",
                            align: "start",
                            labels: {
                                filter: (item) => {
                                    return !!item.text;
                                },
                            },
                        },
                        tooltip: {
                            ...this.chartTooltip,
                            displayColors: true,
                            callbacks: {
                                label: (item) => {
                                    const v = this.formatNumber(item.raw, {
                                        mantissa: 0,
                                    });
                                    return `${item.dataset.label}: ${v}`;
                                },
                            },
                            filter: (item) => {
                                return item.dataset.label && item.raw > 0;
                            },
                        },
                    },
                };
            },

            chartPlugins() {
                return [
                    {
                        id: "trendlineEquations",
                        afterDatasetsDraw: (chart, args, options) => {
                            const {
                                ctx,
                                scales: { x, y },
                            } = chart;

                            ctx.fillStyle = this.colors.text;
                            ctx.textAlign = "right";
                            ctx.textBaseline = "bottom";

                            chart.data.datasets.forEach((dataset) => {
                                if (!dataset.trendline) {
                                    return;
                                }

                                const xi = dataset.data.length - 1;
                                const yi = dataset.data[xi];

                                const px = x.getPixelForValue(xi);
                                const py = y.getPixelForValue(yi);

                                const trendline = dataset.trendline;

                                const m = this.formatNumber(
                                    trendline.slope,
                                    this.format,
                                );

                                const b = this.formatNumber(
                                    trendline.intercept,
                                    {
                                        ...this.format,
                                        mantissa: this.format.average
                                            ? this.format.mantissa || 0
                                            : 0,
                                    },
                                );

                                const r2 = this.formatNumber(
                                    trendline.r2 * 100,
                                    this.format,
                                );

                                ctx.fillText(
                                    `y = ${m}x + ${b}; R = ${r2}%`,
                                    px - 5,
                                    py - 5,
                                );
                            });
                        },
                    },
                    {
                        id: "intersection",
                        afterDatasetsDraw: (chart, args, options) => {
                            this.boxRight = chart.width - chart.chartArea.width;
                            this.boxBottom =
                                chart.height - chart.chartArea.height;
                        },
                    },
                ];
            },
        },

        methods: {
            flatline(trend) {
                const data = Object.values(trend).filter(
                    (v) => v !== null && v !== undefined,
                );

                const y = data[data.length - 1];

                const series = [...Array(trend.length).keys()].map(() => y);

                return {
                    slope: 0,
                    intercept: y,
                    series,
                };
            },

            trendline(trend) {
                const [slope, intercept] = this.linearRegression(trend);
                const series = this.linearProjection(
                    slope,
                    intercept,
                    trend.length,
                );

                const r2 = this.r2(trend, series);

                return {
                    slope,
                    intercept,
                    series,
                    r2,
                };
            },

            linearRegression(trend) {
                const data = Object.values(trend).filter(
                    (v) => v !== null && v !== undefined,
                );
                const n = data.length;

                const avgX = n / 2;
                const avgY = data.reduce((s, v) => s + v, 0) / n;

                const rise = data
                    .map((v, i) => (i - avgX) * (v - avgY))
                    .reduce((s, v) => s + v, 0);

                const run = data
                    .map((v, i) => (i - avgX) ** 2)
                    .reduce((s, v) => s + v, 0);

                const slope = rise / run;

                const intercept = avgY - slope * avgX;

                return [slope, intercept];
            },

            linearProjection(slope, intercept, n) {
                return [...Array(n).keys()]
                    .map((i) => slope * i + intercept)
                    .map((v) => (v > 0 ? v : null));
            },

            r2(trend, series) {
                const data = Object.values(trend).filter(
                    (v) => v !== null && v !== undefined,
                );
                const n = data.length;

                const avgY = data.reduce((s, v) => s + v, 0) / n;

                const ssr = data.reduce((s, y, i) => s + (y - series[i]) ** 2);
                const sst = data.reduce((s, y) => s + (y - avgY) ** 2);

                return 1 - ssr / sst;
            },
        },
    };
</script>
