<template>
    <modal
        role="alertdialog"
        :show="show"
        size="md"
    >
        <div
            class="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden w-[460px]"
        >
            <modal-header>
                {{ __("Delete File") }}
            </modal-header>
            <modal-content>
                <p class="leading-tight">
                    {{ __("Are you sure you want to delete this file?") }}
                </p>
            </modal-content>
            <modal-footer>
                <div class="ml-auto">
                    <v-button
                        as="button"
                        class="mr-3"
                        dusk="cancel-upload-delete-button"
                        @click.prevent="handleClose"
                    >
                        {{ __("Cancel") }}
                    </v-button>

                    <v-button
                        ref="confirmButton"
                        dusk="confirm-upload-delete-button"
                        :label="__('Delete')"
                        :loading="working"
                        severity="danger"
                        @click.prevent="handleConfirm"
                    />
                </div>
            </modal-footer>
        </div>
    </modal>
</template>

<script>
    import Modal from "@nova/components/Modals/Modal.vue";
    import ModalContent from "@nova/components/Modals/ModalContent.vue";
    import ModalFooter from "@nova/components/Modals/ModalFooter.vue";
    import ModalHeader from "@nova/components/Modals/ModalHeader.vue";
    import VButton from "@nova/components/Buttons/VButton.vue";

    export default {
        components: {
            Modal,
            ModalContent,
            ModalFooter,
            ModalHeader,
            VButton,
        },

        props: {
            show: { type: Boolean, default: false },
        },

        emits: ["confirm", "close"],
        data: () => ({ working: false }),

        watch: {
            show(showing) {
                if (showing === false) {
                    this.working = false;
                }
            },
        },

        methods: {
            handleClose() {
                this.working = false;
                this.$emit("close");
            },

            handleConfirm() {
                this.working = true;
                this.$emit("confirm");
            },
        },
    };
</script>
