<template>
    <icon
        class="transform transition-all"
        :class="{ '-rotate-90': collapsed }"
        name="mdi-chevron-down"
    />
</template>

<script>
    import Icon from "@nova/components/Icon.vue";

    export default {
        components: {
            Icon,
        },

        props: {
            collapsed: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>
