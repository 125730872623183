<template>
    <!-- Pagination -->
    <component
        :is="paginationComponent"
        :all-matching-resource-count="allMatchingResourceCount"
        :current-resource-count="currentResourceCount"
        :next="hasNextPage"
        :page="currentPage"
        :pages="totalPages"
        :per-page="perPage"
        :previous="hasPreviousPage"
        :resource-count-label="resourceCountLabel"
        @load-more="loadMore"
        @page="selectPage"
    >
        <span
            v-if="resourceCountLabel"
            class="text-xs px-4"
            :class="{
                'ml-auto hidden md:inline':
                    paginationComponent === 'pagination-links',
            }"
        >
            {{ resourceCountLabel }}
        </span>
    </component>
</template>

<script>
    import PaginationLinks from "@nova/components/Pagination/PaginationLinks.vue";

    export default {
        components: {
            PaginationLinks,
        },

        props: [
            "paginationComponent",
            "hasNextPage",
            "hasPreviousPage",
            "loadMore",
            "selectPage",
            "totalPages",
            "currentPage",
            "perPage",
            "resourceCountLabel",
            "currentResourceCount",
            "allMatchingResourceCount",
        ],
    };
</script>
