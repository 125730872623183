<script>
    import each from "lodash/each";
    import filter from "lodash/filter";
    import find from "lodash/find";
    import isNil from "lodash/isNil";
    import isObject from "lodash/isObject";
    import map from "lodash/map";
    import tap from "lodash/tap";
    import trim from "lodash/trim";
    import { Errors } from "form-backend-validation";
    import { mapProps } from "@nova/utils";

    export default {
        props: {
            ...mapProps([
                "resourceName",
                "viaResource",
                "viaResourceId",
                "viaRelationship",
                "relationshipType",
            ]),

            actions: {
                type: Array,
                default: () => [],
            },

            pivotActions: {
                type: Object,
                default: () => ({ name: "Pivot", actions: [] }),
            },

            selectedResources: {
                type: [Array, String],
                default: () => [],
            },

            endpoint: String,
        },

        emits: ["actionExecuted"],

        data() {
            return {
                working: false,
                errors: new Errors(),
                actionModalVisible: false,
                responseModalVisible: false,
                selectedActionKey: "",
                actionResponseData: null,
            };
        },

        computed: {
            actionEndpoint() {
                return this.endpoint || `/nova-api/${this.resourceName}/action`;
            },

            selectedAction() {
                return this.selectedActionKey
                    ? find(
                          this.allActions,
                          (a) => a.uriKey === this.selectedActionKey,
                      )
                    : null;
            },

            allActions() {
                return this.actions.concat(this.pivotActions?.actions || []);
            },

            encodedFilters() {
                return this.$store.getters[
                    `${this.resourceName}/currentEncodedFilters`
                ];
            },

            searchParameter() {
                return this.viaRelationship
                    ? this.viaRelationship + "_search"
                    : this.resourceName + "_search";
            },

            currentSearch() {
                return (
                    this.$store.getters.queryStringParams[
                        this.searchParameter
                    ] || ""
                );
            },

            trashedParameter() {
                return this.viaRelationship
                    ? this.viaRelationship + "_trashed"
                    : this.resourceName + "_trashed";
            },

            currentTrashed() {
                return (
                    this.$store.getters.queryStringParams[
                        this.trashedParameter
                    ] || ""
                );
            },

            availableActions() {
                return filter(
                    this.actions,
                    (action) =>
                        this.selectedResources.length > 0 && !action.standalone,
                );
            },

            availablePivotActions() {
                if (!this.pivotActions) {
                    return [];
                }

                return filter(this.pivotActions.actions, (action) => {
                    if (this.selectedResources.length === 0) {
                        return action.standalone;
                    }

                    return true;
                });
            },

            hasPivotActions() {
                return this.availablePivotActions.length > 0;
            },

            selectedActionIsPivotAction() {
                return (
                    this.hasPivotActions &&
                    Boolean(
                        find(
                            this.pivotActions.actions,
                            (a) => a === this.selectedAction,
                        ),
                    )
                );
            },

            actionRequestQueryString() {
                return {
                    action: this.selectedActionKey,
                    pivotAction: this.selectedActionIsPivotAction,
                    search: this.currentSearch,
                    filters: this.encodedFilters,
                    trashed: this.currentTrashed,
                    viaResource: this.viaResource,
                    viaResourceId: this.viaResourceId,
                    viaRelationship: this.viaRelationship,
                };
            },

            actionFormData() {
                return tap(new FormData(), (formData) => {
                    if (this.selectedResources === "all") {
                        formData.append("resources", "all");
                    } else {
                        const pivotIds = filter(
                            map(this.selectedResources, (resource) =>
                                isObject(resource)
                                    ? resource.id.pivotValue
                                    : null,
                            ),
                        );

                        each(this.selectedResources, (resource) =>
                            formData.append(
                                "resources[]",
                                isObject(resource)
                                    ? resource.id.value
                                    : resource,
                            ),
                        );

                        if (
                            this.selectedResources !== "all" &&
                            this.selectedActionIsPivotAction === true &&
                            pivotIds.length > 0
                        ) {
                            formData.append("pivots", pivotIds);
                        }
                    }

                    each(this.selectedAction.fields, (field) => {
                        field.fill(formData);
                    });
                });
            },
        },

        methods: {
            determineActionStrategy() {
                if (this.selectedAction.withoutConfirmation) {
                    this.executeAction();
                } else {
                    this.openConfirmationModal();
                }
            },

            openConfirmationModal() {
                this.actionModalVisible = true;
            },

            closeConfirmationModal() {
                this.actionModalVisible = false;
            },

            openResponseModal() {
                this.responseModalVisible = true;
            },

            closeResponseModal() {
                this.responseModalVisible = false;
            },

            emitResponseCallback(callback) {
                this.$emit("actionExecuted", this.selectedAction);
                Nova.$emit("action-executed", this.selectedAction);

                if (typeof callback === "function") {
                    callback();
                }
            },

            showActionResponseMessage(data) {
                if (data.danger) {
                    return Nova.error(data.danger);
                }

                if (data.link) {
                    return;
                }

                Nova.success(
                    data.message ||
                        this.__("The action was executed successfully."),
                );
            },

            executeAction(then) {
                if (this.selectedAction.response) {
                    this.handleActionResponse(
                        this.selectedAction.response.data,
                        this.selectedAction.response.headers,
                        then,
                        false,
                    );

                    return;
                }

                this.working = true;
                Nova.$progress.start();

                const responseType = this.selectedAction.responseType ?? "json";

                Nova.request({
                    method: "post",
                    url: this.actionEndpoint,
                    params: this.actionRequestQueryString,
                    data: this.actionFormData,
                    responseType,
                })
                    .then(async (response) => {
                        this.closeConfirmationModal();
                        this.handleActionResponse(
                            response.data,
                            response.headers,
                            then,
                        );
                    })
                    .catch((error) => {
                        if (error.response && error.response.status === 422) {
                            if (responseType === "blob") {
                                error.response.data.text().then((data) => {
                                    this.errors = new Errors(
                                        JSON.parse(data).errors,
                                    );
                                });
                            } else {
                                this.errors = new Errors(
                                    error.response.data.errors,
                                );
                            }

                            Nova.error(
                                __("There was a problem executing the action."),
                            );
                        }
                    })
                    .finally(() => {
                        this.working = false;
                        Nova.$progress.done();
                    });
            },

            handleActionResponse(data, headers, then, showMessage = true) {
                const contentDisposition = headers["content-disposition"];

                if (
                    data instanceof Blob &&
                    isNil(contentDisposition) &&
                    data.type === "application/json"
                ) {
                    data.text().then((jsonStringData) => {
                        this.handleActionResponse(
                            JSON.parse(jsonStringData),
                            headers,
                        );
                    });

                    return;
                }

                if (data instanceof Blob) {
                    return this.emitResponseCallback(async () => {
                        let fileName = "unknown";

                        if (contentDisposition) {
                            const fileNameMatch = contentDisposition
                                .split(";")[1]
                                .match(/filename=(.+)/);
                            if (fileNameMatch.length === 2) {
                                fileName = trim(fileNameMatch[1], '"');
                            }
                        }

                        await this.$nextTick();

                        const url = window.URL.createObjectURL(
                            new Blob([data]),
                        );
                        const link = document.createElement("a");

                        link.href = url;
                        link.setAttribute("download", fileName);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();

                        window.URL.revokeObjectURL(url);
                    });
                }

                if (data.modal) {
                    this.actionResponseData = data;

                    this.showActionResponseMessage(data);

                    return this.openResponseModal();
                }

                if (data.download) {
                    return this.emitResponseCallback(async () => {
                        this.showActionResponseMessage(data);

                        await this.$nextTick();

                        const link = document.createElement("a");
                        link.href = data.download;
                        link.download = data.name;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    });
                }

                if (data.deleted) {
                    return this.emitResponseCallback(() =>
                        this.showActionResponseMessage(data),
                    );
                }

                if (data.redirect) {
                    window.location = data.redirect;
                    return;
                }

                if (data.visit) {
                    this.showActionResponseMessage(data);

                    return Nova.visit({
                        url: Nova.url(data.visit.path, data.visit.options),
                        remote: false,
                    });
                }

                if (data.openInNewTab) {
                    return this.emitResponseCallback(() =>
                        window.open(data.openInNewTab, "_blank"),
                    );
                }

                this.emitResponseCallback(
                    () => showMessage && this.showActionResponseMessage(data),
                );
            },

            handleActionClick(uriKey) {
                this.selectedActionKey = uriKey;
                this.determineActionStrategy();
            },

            setSelectedActionKey(key) {
                this.selectedActionKey = key;
            },
        },
    };
</script>
