<template>
    <div :class="`text-${field.textAlign}`">
        <component
            :is="resource.routeToDetail ? 'a' : 'link'"
            v-if="fieldHasValue && !isPivot && authorizedToView"
            class="link-default"
            :href="
                resource.routeToDetail ||
                $url(`/resources/${resourceName}/${field.value}`)
            "
            @click.stop
        >
            {{ fieldValue }}
        </component>
        <p v-else-if="fieldHasValue || isPivot">
            {{ field.pivotValue || fieldValue }}
        </p>
        <p v-else>{{ __("&mdash;") }}</p>
    </div>
</template>

<script>
    import isNil from "lodash/isNil";
    import DisplayField from "@nova/extendables/DisplayField.vue";

    export default {
        extends: DisplayField,

        computed: {
            isPivot() {
                return !isNil(this.field.pivotValue);
            },

            authorizedToView() {
                return this.resource?.authorizedToView ?? false;
            },
        },
    };
</script>
