<template>
    <div class="flex flex-col">
        <div class="grid grid-cols-6 gap-4">
            <div
                v-for="(trend, i) in data.trends"
                :key="trend.name"
                class="flex flex-col gap-1 border-t-4 p-1 rounded bg-white hover:bg-gray-100 cursor-pointer transition-colors"
                :class="{
                    'border-blue-500': selected === i,
                }"
                @click="selected = i"
            >
                <span v-text="trend.name"></span>
                <span
                    class="text-3xl"
                    v-text="formattedValue(trend)"
                ></span>
                <span
                    class="flex items-center text-xs"
                    :style="`color: ${deltaColor(trend)};`"
                >
                    <icon
                        class="!w-4 !h-4"
                        :name="deltaIcon(trend)"
                    />
                    <span v-text="deltaPercentage(trend) + '%'"></span>
                </span>
            </div>
            <div class="col-start-6">
                <slot name="controls"></slot>
            </div>
        </div>

        <div class="flex-1">
            <canvas ref="chart"></canvas>
        </div>
    </div>
</template>

<script>
    import BaseChartMetric from "./BaseChartMetric.vue";
    import Icon from "@nova/components/Icon.vue";

    export default {
        components: {
            Icon,
        },

        extends: BaseChartMetric,

        data: () => ({
            selected: 0,
        }),

        computed: {
            selectedValueTrend() {
                if (!this.data.trends) {
                    return {};
                }

                return this.data.trends[this.selected] || {};
            },

            currentTrend() {
                return this.selectedValueTrend?.current?.trend || {};
            },

            previousTrend() {
                return this.selectedValueTrend?.previous?.trend || {};
            },

            labels() {
                return Object.keys(this.currentTrend);
            },

            chartType() {
                return "line";
            },

            chartData() {
                if (!this.labels) {
                    return {};
                }

                return {
                    labels: this.labels,
                    datasets: [
                        {
                            label: "Current Period",
                            data: Object.values(this.currentTrend),
                            keys: Object.keys(this.currentTrend),
                            fill: false,
                            borderColor: this.colors.primary,
                            pointRadius: 0,
                            pointHoverRadius: 3,
                            pointBackgroundColor: this.colors.primary,
                        },
                        {
                            label: "Preceding Period",
                            data: Object.values(this.previousTrend),
                            keys: Object.keys(this.previousTrend),
                            fill: false,
                            borderColor: this.colors.secondary,
                            borderWidth: 1,
                            borderDash: [5, 5],
                            pointRadius: 0,
                            pointHoverRadius: 3,
                            pointBackgroundColor: this.colors.secondary,
                        },
                    ],
                };
            },

            chartOptions() {
                return {
                    interaction: {
                        mode: "index",
                        intersect: false,
                    },
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            grid: {
                                display: false,
                            },
                        },
                        y: {
                            grid: {
                                tickColor: "white",
                            },
                            border: {
                                display: false,
                            },
                            position: "right",
                            ticks: {
                                maxTicksLimit: 5,
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            position: "bottom",
                            align: "start",
                            labels: {
                                pointStyle: "line",
                                usePointStyle: true,
                            },
                        },
                        tooltip: {
                            ...this.chartTooltip,
                            displayColors: true,
                            callbacks: {
                                title: (contexts) => {
                                    const [c1, c2] = contexts;

                                    const current = c1.label;

                                    if (!c2) {
                                        return current;
                                    }

                                    const previous =
                                        c2.dataset.keys[c2.dataIndex];

                                    return current + " vs " + previous;
                                },
                                footer: (contexts) => {
                                    const current = contexts[0].raw;
                                    const previous = contexts[1].raw;

                                    const ratio = this.getChangeRatio(
                                        current,
                                        previous,
                                    );

                                    if (ratio === 0) {
                                        return "No Change";
                                    }

                                    if (ratio === null) {
                                        return "No Prior Data";
                                    }

                                    const percent = this.formatNumber(
                                        Math.abs(ratio * 100),
                                        {
                                            mantissa: 1,
                                        },
                                    );

                                    const label =
                                        ratio > 0 ? "Increase" : "Decrease";

                                    return `${percent}% ${label}`;
                                },
                            },
                        },
                    },
                };
            },
        },

        methods: {
            formattedValue(trend) {
                return this.formatNumber(
                    trend.current.value,
                    this.format || {
                        average: true,
                        optionalMantissa: true,
                        mantissa: 2,
                    },
                );
            },

            deltaColor(trend) {
                return this.getDeltaColor(this.increaseOrDecrease(trend));
            },

            deltaIcon(trend) {
                return this.getDeltaIcon(this.increaseOrDecrease(trend));
            },

            deltaPercentage(trend) {
                return this.getDeltaPercentage(this.changeRatio(trend));
            },

            increaseOrDecrease(trend) {
                return this.getIncreaseOrDecrease(this.changeRatio(trend));
            },

            changeRatio(trend) {
                return this.getChangeRatio(
                    trend.current.value,
                    trend.previous.value,
                );
            },
        },
    };
</script>
