import BaseEditor from "./BaseEditor";

class ContractSection extends BaseEditor {}

ContractSection.prototype.toolbarButtons = [
    "bold",
    "italic",
    "underline",
    "insertLink",
    "formatOL",
    "formatUL",
];

export default ContractSection;
