import BaseEditor from "./BaseEditor";

class Minimal extends BaseEditor {}

Minimal.prototype.toolbarButtons = [
    "bold",
    "italic",
    "underline",
    "formatOL",
    "formatUL",
    "textColor",
    "backgroundColor",
    "lineHeight",
    "paragraphFormat",
    "paragraphStyle",
    "inlineStyle",
    "clearFormatting",
];

export default Minimal;
