<script>
    import { mapGetters, mapMutations } from "vuex";
    import { v4 as uuidv4 } from "uuid";

    export default {
        data: () => ({
            formUniqueId: uuidv4(),
        }),

        computed: {
            ...mapGetters(["canLeaveModal"]),
        },

        methods: {
            ...mapMutations(["allowLeavingModal", "preventLeavingModal"]),

            updateModalStatus() {
                this.preventLeavingModal();
            },

            handlePreventModalAbandonment(proceed, revert) {
                if (this.canLeaveModal) {
                    proceed();
                    return;
                }

                if (
                    window.confirm(
                        this.__(
                            "Do you really want to leave? You have unsaved changes.",
                        ),
                    )
                ) {
                    this.allowLeavingModal();
                    proceed();
                    return;
                }

                revert();
            },
        },
    };
</script>
