<template>
    <div class="border rounded shadow-inner relative">
        <copy-button
            v-if="copyable"
            class="absolute top-0 right-0 z-10 mt-2"
            @click.prevent.stop="copy"
        />
        <codemirror
            v-if="!loading"
            v-model="value"
            :autofocus="false"
            :disabled="disabled"
            :extensions="extensions"
            :indent-with-tab="false"
            :tab-size="4"
            @change="handleChange"
        />
    </div>
</template>

<script>
    import { useClipboard } from "@vueuse/core";
    import CopyButton from "@nova/components/Buttons/CopyButton.vue";
    import { defineAsyncComponent } from "vue";

    export default {
        components: {
            CopyButton,

            Codemirror: defineAsyncComponent(() =>
                import("vue-codemirror").then((module) => module.Codemirror),
            ),
        },

        props: {
            language: {
                type: String,
                required: true,

                validator(value) {
                    return ["json", "html"].includes(value);
                },
            },

            theme: {
                type: String,
                default: "dracula",

                validator(value) {
                    return ["dracula"].includes(value);
                },
            },

            copyable: {
                type: Boolean,
                default: false,
            },

            modelValue: {},
            disabled: Boolean,
        },

        emits: ["update:modelValue", "change"],

        data() {
            return {
                value: this.modelValue,
                emitting: false,
                languageExtension: null,
                themeExtension: null,
                loading: true,
            };
        },

        computed: {
            extensions() {
                return [this.languageExtension, this.themeExtension].filter(
                    (v) => v,
                );
            },
        },

        watch: {
            modelValue(newValue) {
                if (this.emitting) {
                    return;
                }

                this.value = newValue;
            },

            language: {
                immediate: true,

                handler() {
                    this.loadExtensions();
                },
            },

            theme: {
                immediate: true,

                handler() {
                    this.loadExtensions();
                },
            },
        },

        methods: {
            handleChange(value) {
                this.emitting = true;
                this.$emit("update:modelValue", value);
                this.$emit("change", value);
                this.emitting = false;
            },

            copy() {
                useClipboard({
                    source: this.value,
                    legacy: true,
                }).copy(this.value);
            },

            async loadExtensions() {
                this.loading = true;
                this.languageExtension = await this.loadLanguageExtension();
                this.themeExtension = await this.loadThemeExtension();
                this.loading = false;
            },

            async loadLanguageExtension() {
                switch (this.language?.toLowerCase()) {
                    case "html":
                        return (await import("@codemirror/lang-html")).html();
                    case "json":
                        return (await import("@codemirror/lang-json")).json();
                    default:
                        return null;
                }
            },

            async loadThemeExtension() {
                switch (this.theme?.toLowerCase()) {
                    case "dracula":
                        return (await import("thememirror")).dracula;
                    default:
                        return null;
                }
            },
        },
    };
</script>

<style>
    .cm-editor {
        border-radius: 0.25rem;
    }

    .cm-gutters {
        border-radius: 0.25rem;
    }
</style>
