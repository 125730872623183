<template>
    <div class="flex flex-col gap-2">
        <h4
            v-if="title"
            class="m-0 p-0"
            v-text="title"
        />
        <div
            class="flex-1 grid grid-cols-3 gap-4 my-auto max-h-40 items-center"
        >
            <div
                class="text-xs col-span-2"
                :style="{ color: colors.text }"
            >
                <ul class="list-none ml-0">
                    <li
                        v-for="item in items"
                        :key="item.label"
                    >
                        <span
                            class="inline-block rounded-full w-2 h-2 mr-2"
                            :style="{
                                backgroundColor: item.color,
                            }"
                        />
                        <span>
                            {{ item.label }} ({{ item.value }} -
                            {{ item.percent }}%)
                        </span>
                    </li>
                </ul>
            </div>
            <div>
                <canvas
                    ref="chart"
                    height="150"
                ></canvas>
            </div>
        </div>
        <div class="absolute left-0 bottom-0 p-2">Total: {{ total }}</div>
        <help-tooltips>
            <help-range-tooltip :range="range" />
            <slot name="help" />
        </help-tooltips>
    </div>
</template>

<script>
    import BaseRangedChartMetric from "./BaseRangedChartMetric.vue";
    import HelpTooltips from "@nova/components/HelpTooltips.vue";
    import HelpRangeTooltip from "@nova/components/HelpRangeTooltip.vue";

    export default {
        components: {
            HelpTooltips,
            HelpRangeTooltip,
        },

        extends: BaseRangedChartMetric,

        computed: {
            total() {
                return this.formatNumber(
                    this.data?.partition?.reduce(
                        (c, item) => c + item.value,
                        0,
                    ),
                    this.format,
                );
            },

            items() {
                if (!this.data?.partition) {
                    return {};
                }

                const partition = this.data.partition;

                const total = partition.reduce((c, item) => c + item.value, 0);
                const percent = partition.map((item) => item.value / total);

                return partition.map((item, i) => {
                    return {
                        label: item.label,
                        value: this.formatNumber(item.value, this.format),
                        percent: this.formatNumber(percent[i] * 100, {
                            mantissa: 2,
                        }),
                        color: this.getItemColor(item, i),
                    };
                });
            },

            chartType() {
                return "doughnut";
            },

            chartData() {
                if (!this.data?.partition) {
                    return {};
                }

                const partition = this.data.partition;

                return {
                    labels: partition.map((item, i) => item.label),
                    datasets: [
                        {
                            data: partition.map((item) => item.value),
                            backgroundColor: partition.map((item, i) =>
                                this.getItemColor(item, i),
                            ),
                            cutout: "65%",
                        },
                    ],
                };
            },

            chartOptions() {
                return {
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            ...this.chartTooltip,
                        },
                    },
                };
            },

            defaultColors() {
                return [
                    window.theme.colors.blue["500"],
                    window.theme.colors.orange["500"],
                    window.theme.colors.red["500"],
                    window.theme.colors.yellow["400"],
                    window.theme.colors.green["500"],
                    window.theme.colors.green["700"],
                    "#06b6d4", // sky 500
                    window.theme.colors.indigo["500"],
                    "plum",
                    "hotpink",
                    "darkorchid",
                    "navajowhite",
                    "sandybrown",
                    window.theme.colors.yellow["800"],
                    "yellowgreen",
                    "darkgoldenrod",
                    "olive",
                    window.theme.colors.gray["400"],
                    window.theme.colors.gray["600"],
                    window.theme.colors.gray["800"],
                ];
            },
        },

        methods: {
            getItemColor(item, index) {
                return item.color || this.colorForIndex(index);
            },

            colorForIndex(index) {
                return this.defaultColors[index % this.defaultColors.length];
            },
        },
    };
</script>
