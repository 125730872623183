export default {
    name: "imageSelector",
    icon: { NAME: "imageManager", SVG_KEY: "imageManager" },
    command: {
        title: "Image Selector",
        focus: false,
        undo: false,
        refreshAfterCallback: false,
        callback: function () {
            // Get the editor instance
            const editor = this;

            $.ajax({
                url: "/image/select.php",
                dataType: "html",
                type: "GET",
            }).done(function (resp) {
                $("#selector-modal").html(resp).foundation("open");
            });

            $("#selector-modal[data-reveal]").on(
                "closed.zf.reveal",
                function () {
                    const selected = {};
                    $("#selector-modal")
                        .contents()
                        .find("input.selected")
                        .each(function () {
                            selected[$(this).attr("name")] = $(this).val();
                        });

                    if (selected.filekey) {
                        const href = `/image/display.php?id=${selected.imagekey}&width=${selected.width}&height=${selected.height}&crop=0`;

                        // Insert the image element at the cursor position
                        editor.selection.restore(); // Restore selection if needed
                        editor.html.insert(
                            `<img src="${href}" width="${selected.width}" height="${selected.height}" alt="${selected.name}" >`,
                        );
                    }
                },
            );
        },
    },
};
