<template>
    <div class="flex gap-2">
        <popper>
            <template #trigger="{ popper }">
                <v-button
                    :aria-label="__('Filter Dropdown')"
                    dusk="filter-selector-button"
                    icon="mdi-filter"
                    :label="activeFilterCount > 0 ? activeFilterCount : ''"
                    padding="tight"
                    :severity="filtersAreApplied ? 'info' : 'light'"
                    trailing-icon="mdi-chevron-down"
                    :variant="filtersAreApplied ? 'solid' : 'outline'"
                    @click="popper.toggle()"
                />
            </template>
            <template #content="{ popper }">
                <dropdown-menu
                    dusk="filter-menu"
                    width="300"
                >
                    <scroll-wrap
                        class="bg-white dark:bg-gray-900"
                        :height="350"
                    >
                        <div>
                            <div
                                v-if="filters.length > 0"
                                class="bg-gray-800"
                            >
                                <button-group class="grid grid-cols-3">
                                    <v-button
                                        v-for="option in filterDockOptions"
                                        :key="option.value"
                                        :class="{
                                            'bg-gray-700':
                                                selectedFilterDockOption.value ===
                                                option.value,
                                        }"
                                        :icon="option.icon"
                                        padding="tight"
                                        severity="light"
                                        size="lg"
                                        :title="__(option.label)"
                                        variant="ghost"
                                        @click="
                                            $emit('dock-changed', option);
                                            popper.update();
                                        "
                                    />
                                </button-group>
                            </div>
                            <v-button
                                v-if="filtersAreApplied"
                                class="border-0 w-full rounded-none uppercase"
                                :label="__('Reset Filters')"
                                severity="muted"
                                size="sm"
                                @click="handleClearSelectedFiltersClick"
                            />

                            <field-grid
                                class="p-4"
                                data-dock="undock"
                                full-width
                                stacked
                            >
                                <!-- Custom Filters -->
                                <template v-if="dockSelf">
                                    <component
                                        :is="filter.component"
                                        v-for="(
                                            filter, index
                                        ) in unpinnedFilters"
                                        :key="`${filter.class}-${index}`"
                                        :filter-key="filter.class"
                                        :lens="lens"
                                        :resource-name="resourceName"
                                        @change="handleFilterChanged"
                                        @close="popper.unfreeze()"
                                        @open="popper.freeze()"
                                    />
                                </template>

                                <!-- Soft Deletes -->
                                <filter-container
                                    v-if="softDeletes"
                                    dusk="filter-soft-deletes"
                                >
                                    <span>{{ __("Trashed") }}</span>

                                    <template #filter>
                                        <select-control
                                            v-model:selected="trashedValue"
                                            dusk="trashed-select"
                                            :options="[
                                                {
                                                    value: '',
                                                    label: __('&mdash;'),
                                                },
                                                {
                                                    value: 'with',
                                                    label: __('With Trashed'),
                                                },
                                                {
                                                    value: 'only',
                                                    label: __('Only Trashed'),
                                                },
                                            ]"
                                            @change="trashedValue = $event"
                                        />
                                    </template>
                                </filter-container>

                                <!-- Per Page -->
                                <filter-container
                                    v-if="!viaResource"
                                    dusk="filter-per-page"
                                >
                                    <span>{{ __("Per Page") }}</span>

                                    <template #filter>
                                        <select-control
                                            v-model:selected="perPageValue"
                                            dusk="per-page-select"
                                            :options="perPageOptionsForFilter"
                                            @change="perPageValue = $event"
                                        />
                                    </template>
                                </filter-container>
                            </field-grid>
                        </div>
                    </scroll-wrap>
                </dropdown-menu>
            </template>
        </popper>

        <!-- Pinned Filters -->
        <filter-teleport
            v-if="ready"
            :filters="pinnedFilters"
            :lens="lens"
            stacked
            :to="filterDockPinnedTarget"
            :width="dockLeft ? 'full' : '1/6'"
            @filter-changed="handleFilterChanged"
        />

        <!-- Externally Docked Filters -->
        <filter-teleport
            v-if="!dockSelf"
            :filters="unpinnedFilters"
            :lens="lens"
            stacked
            :to="filterDockTarget"
            :width="defaultWidth"
            @filter-changed="handleFilterChanged"
        />
    </div>
</template>

<script>
    import ButtonGroup from "@nova/components/Buttons/ButtonGroup.vue";
    import DropdownMenu from "@nova/components/Dropdowns/DropdownMenu.vue";
    import FieldGrid from "@nova/components/FieldGrid.vue";
    import FilterContainer from "@nova/components/Filters/FilterContainer.vue";
    import FilterTeleport from "@nova/components/FilterTeleport.vue";
    import Popper from "@nova/components/Popper.vue";
    import ScrollWrap from "@nova/components/ScrollWrap.vue";
    import SelectControl from "@nova/components/Inputs/SelectControl.vue";
    import VButton from "@nova/components/Buttons/VButton.vue";
    import map from "lodash/map";
    import { computed, nextTick } from "vue";

    export default {
        components: {
            ButtonGroup,
            DropdownMenu,
            FieldGrid,
            FilterContainer,
            FilterTeleport,
            Popper,
            ScrollWrap,
            SelectControl,
            VButton,
        },

        inject: [
            "resourceName",
            "viaResource",
            "filterDockTarget",
            "filterDockOptions",
            "filterDockPinnedTarget",
            "selectedFilterDockOption",
        ],

        provide() {
            return {
                stacked: true,
                defaultWidth: computed(() => this.defaultWidth),
            };
        },

        props: {
            activeFilterCount: Number,
            filters: Array,
            filtersAreApplied: Boolean,
            lens: { type: String, default: "" },
            perPage: [String, Number],
            perPageOptions: Array,
            softDeletes: Boolean,

            trashed: {
                type: String,
                validator: (v) => ["", "with", "only"].includes(v),
            },
        },

        emits: [
            "dock-changed",
            "trashed-changed",
            "per-page-changed",
            "filter-changed",
            "clear-selected-filters",
        ],

        data: () => ({
            ready: false,
        }),

        computed: {
            trashedValue: {
                set(event) {
                    let value = event?.target?.value || event;

                    this.$emit("trashed-changed", value);
                },

                get() {
                    return this.trashed;
                },
            },

            perPageValue: {
                set(event) {
                    let value = event?.target?.value || event;

                    this.$emit("per-page-changed", value);
                },

                get() {
                    return this.perPage;
                },
            },

            perPageOptionsForFilter() {
                return map(this.perPageOptions, (option) => {
                    return { value: option, label: option };
                });
            },

            dockSelf() {
                return this.selectedFilterDockOption.value === "undock";
            },

            dockTop() {
                return this.selectedFilterDockOption.value === "top";
            },

            dockLeft() {
                return this.selectedFilterDockOption.value === "left";
            },

            pinnedFilters() {
                return this.filters.filter((f) => f.pinned);
            },

            unpinnedFilters() {
                return this.filters.filter((f) => !f.pinned);
            },

            defaultWidth() {
                return this.dockTop ? "1/6" : "full";
            },
        },

        async mounted() {
            await nextTick();

            this.ready = true;
        },

        methods: {
            handleFilterChanged(v) {
                if (v) {
                    const { filterClass, value } = v;

                    if (filterClass) {
                        this.$store.commit(
                            `${this.resourceName}/updateFilterState`,
                            {
                                filterClass,
                                value,
                            },
                        );

                        Nova.$emit("filter-changed", {
                            filterClass,
                            value,
                        });
                    }
                }

                this.$emit("filter-changed");
            },

            handleClearSelectedFiltersClick() {
                Nova.$emit("clear-filter-values");

                setTimeout(() => {
                    this.$emit("clear-selected-filters");
                }, 500);
            },
        },
    };
</script>
