<template>
    <div :class="`text-${field.textAlign}`">
        <p
            v-if="fieldValue"
            class="inline-flex items-center gap-1"
            :class="alignClass"
            v-bind="$attrs"
        >
            <span v-if="!field.asHtml">
                <slot
                    :item="this"
                    name="field-value"
                >
                    {{ shouldFormat ? formattedValue : fieldValue }}
                </slot>
            </span>
            <div
                v-else
                @click.stop
                v-html="fieldValue"
            />
            <copy-button
                v-if="fieldValue && field.copyable"
                @click.prevent.stop="copy"
            />
        </p>
        <p v-else>&mdash;</p>
    </div>
</template>

<script>
    import DisplayField from "@nova/extendables/DisplayField.vue";
    import { formatNumber } from "@nova/utils";

    export default {
        extends: DisplayField,
        inheritAttrs: false,

        computed: {
            shouldFormat() {
                return this.field.type === 'number'
                    && (
                        ! this.field.usesCustomizedDisplay
                        || Math.abs(this.field.value - this.field.displayedAs) < 1e-10
                        || this.field.step > 0
                    );
            },

            formattedValue() {
                return formatNumber(this.fieldValue, {
                    mantissa: this.mantissa,
                });
            },

            mantissa() {
                if (this.field.step == 0 || !this.field.step) {
                    return 0;
                }

                if (this.field.step >= 1) {
                    return 0;
                }

                return Math.abs(Math.floor(Math.log10(this.field.step)));
            },
        },

        methods: {
            formatNumber
        }
    };
</script>
