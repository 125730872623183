<template>
    <component
        :is="card?.component || 'card'"
        :card="card"
        class="h-full"
        :class="[widthClass, heightClass]"
        v-bind="$attrs"
    >
        <slot />
    </component>
</template>

<script>
    import Card from "@nova/components/Card.vue";

    export default {
        components: {
            Card,
        },

        props: {
            /**
             * @property {Object}        card
             * @property {string="card"} card.component
             * @property {string=}       card.width ("1/4"|"1/3"|"1/2"|"2/3"|"3/4"|"full")
             * @property {string=}       card.height ("sm"|"md"|"lg")
             */
            card: Object,
            width: String,
            height: String,
        },

        computed: {
            widthSize() {
                return this.card?.width || this.width || "1/3";
            },

            heightSize() {
                return this.card?.height || this.height || "md";
            },

            widthClass() {
                return {
                    "1/4": "md:col-span-3",
                    "1/3": "md:col-span-4",
                    "1/2": "md:col-span-6",
                    "2/3": "md:col-span-8",
                    "3/4": "md:col-span-9",
                    full: "md:col-span-12",
                }[this.widthSize];
            },

            heightClass() {
                return {
                    fixed: "min-h-40",
                    md: "min-h-40",
                    lg: "min-h-[25rem]",
                }[this.heightSize];
            },
        },
    };
</script>
