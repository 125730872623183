<template>
    <div
        class="flex relative"
        :class="$attrs.class"
    >
        <select
            v-bind="defaultAttributes"
            ref="selectControl"
            class="form-control"
            :class="{
                'h-8 text-xs': size === 'sm',
                'h-7 text-xs': size === 'xs',
                'h-6 text-xs': size === 'xxs',
                'form-control-bordered-error': hasError,
                'form-input-disabled': disabled,
            }"
            :data-disabled="disabled ? 'true' : null"
            @change="handleChange"
        >
            <slot />
            <template v-for="(groupOptions, group) in groupedOptions">
                <optgroup
                    v-if="group"
                    :key="group"
                    :label="group"
                >
                    <option
                        v-for="option in groupOptions"
                        v-bind="attrsFor(option)"
                        :key="option.value"
                        :selected="isSelected(option)"
                    >
                        {{ labelFor(option) }}
                    </option>
                </optgroup>
                <template v-else>
                    <option
                        v-for="option in groupOptions"
                        v-bind="attrsFor(option)"
                        :key="option.value"
                        :selected="isSelected(option)"
                    >
                        {{ labelFor(option) }}
                    </option>
                </template>
            </template>
        </select>
    </div>
</template>

<script>
    import filter from "lodash/filter";
    import groupBy from "lodash/groupBy";
    import map from "lodash/map";
    import omit from "lodash/omit";

    export default {
        props: {
            hasError: { type: Boolean, default: false },
            label: { default: "label" },
            options: { type: Array, default: () => [] },
            disabled: { type: Boolean, default: false },
            selected: {},

            size: {
                type: String,
                default: "md",
                validator: (val) => ["xxs", "xs", "sm", "md"].includes(val),
            },
        },

        emits: ["change"],

        computed: {
            defaultAttributes() {
                return omit(this.$attrs, ["class"]);
            },

            groupedOptions() {
                return groupBy(this.options, (option) => option.group || "");
            },
        },

        methods: {
            labelFor(option) {
                return this.label instanceof Function
                    ? this.label(option)
                    : option[this.label];
            },

            attrsFor(option) {
                return {
                    ...(option.attrs || {}),
                    ...{ value: option.value },
                };
            },

            isSelected(option) {
                return this.selected.indexOf(option.value) > -1;
            },

            handleChange(event) {
                let selected = map(
                    filter(event.target.options, (option) => option.selected),
                    (option) => option.value,
                );

                this.$emit("change", selected);
            },

            resetSelection() {
                this.$refs.selectControl.selectedIndex = 0;
            },
        },
    };
</script>
