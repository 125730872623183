<script>
    import ResourcePage from "./ResourcePage.vue";
    import filled from "@nova/utils/filled";
    import { Errors } from "form-backend-validation";
    import { mapGetters, mapMutations } from "vuex";
    import { router } from "@inertiajs/vue3";
    import { v4 as uuidv4 } from "uuid";

    export default {
        extends: ResourcePage,

        data() {
            return {
                removeOnNavigationChangesEvent: null,
                removeOnBeforeUnloadEvent: null,
                navigateBackUsingHistory: true,
                validationErrors: new Errors(),
                isWorking: false,
                fileUploadsCount: 0,
                formUniqueId: uuidv4(),
            };
        },

        computed: {
            ...mapGetters(["canLeaveForm", "canLeaveFormToPreviousPage"]),
        },

        created() {
            this.removeOnNavigationChangesEvent = router.on(
                "before",
                (event) => {
                    this.removeOnNavigationChangesEvent();
                    this.handlePreventFormAbandonmentOnInertia(event);
                },
            );

            window.addEventListener(
                "beforeunload",
                this.handlePreventFormAbandonmentOnInertia,
            );

            this.removeOnBeforeUnloadEvent = () => {
                window.removeEventListener(
                    "beforeunload",
                    this.handlePreventFormAbandonmentOnInertia,
                );

                this.removeOnBeforeUnloadEvent = () => {};
            };
        },

        mounted() {
            window.onpopstate = (event) => {
                this.handlePreventFormAbandonmentOnPopState(event);
            };
        },

        beforeUnmount() {
            this.removeOnBeforeUnloadEvent();
        },

        unmounted() {
            this.removeOnNavigationChangesEvent();
            this.resetPushState();
        },

        methods: {
            ...mapMutations([
                "allowLeavingForm",
                "preventLeavingForm",
                "triggerPushState",
                "resetPushState",
            ]),

            /**
             * Prevent accidental abandonment only if form was changed.
             */
            updateFormStatus() {
                if (this.canLeaveForm === true) {
                    this.triggerPushState();
                }

                this.preventLeavingForm();
            },

            enableNavigateBackUsingHistory() {
                this.navigateBackUsingHistory = false;
            },

            disableNavigateBackUsingHistory() {
                this.navigateBackUsingHistory = false;
            },

            handlePreventFormAbandonment(proceed, revert) {
                if (this.canLeaveForm) {
                    proceed();
                    return;
                }

                const answer = window.confirm(
                    this.__(
                        "Do you really want to leave? You have unsaved changes.",
                    ),
                );

                if (answer) {
                    proceed();
                    return;
                }

                revert();
            },

            handlePreventFormAbandonmentOnInertia(event) {
                this.handlePreventFormAbandonment(
                    () => {
                        this.handleProceedingToNextPage();
                        this.allowLeavingForm();
                    },
                    () => {
                        router.ignoreHistoryState = true;
                        event.preventDefault();
                        event.returnValue = "";

                        this.removeOnNavigationChangesEvent = router.on(
                            "before",
                            (event) => {
                                this.removeOnNavigationChangesEvent();
                                this.handlePreventFormAbandonmentOnInertia(
                                    event,
                                );
                            },
                        );
                    },
                );
            },

            handlePreventFormAbandonmentOnPopState(event) {
                event.stopImmediatePropagation();
                event.stopPropagation();

                this.handlePreventFormAbandonment(
                    () => {
                        this.handleProceedingToPreviousPage();
                        this.allowLeavingForm();
                    },
                    () => {
                        this.triggerPushState();
                    },
                );
            },

            handleProceedingToPreviousPage() {
                window.onpopstate = null;
                router.ignoreHistoryState = false;

                this.removeOnBeforeUnloadEvent();

                if (
                    !this.canLeaveFormToPreviousPage &&
                    this.navigateBackUsingHistory
                ) {
                    window.history.back();
                }
            },

            handleProceedingToNextPage() {
                window.onpopstate = null;
                router.ignoreHistoryState = false;

                this.removeOnBeforeUnloadEvent();
            },

            proceedToPreviousPage(url) {
                if (
                    this.navigateBackUsingHistory &&
                    window.history.length > 1
                ) {
                    window.history.back();
                } else if (!this.navigateBackUsingHistory && filled(url)) {
                    Nova.visit(url, { replace: true });
                } else {
                    Nova.visit("/");
                }
            },

            handleResponseError(error) {
                if (
                    error.response === undefined ||
                    error.response.status == 500
                ) {
                    const message =
                        '\nException: "' +
                        (error.response?.statusText || error.message) +
                        '"';

                    Nova.error(
                        this.__("There was a problem submitting the form.") +
                            (Nova.config("debug") ? message : ""),
                    );

                    console.error(error);
                } else if (error.response.status == 422) {
                    this.validationErrors = new Errors(
                        error.response.data.errors,
                    );
                    Nova.error(error.response.data.message);
                } else {
                    Nova.error(
                        this.__("There was a problem submitting the form.") +
                            ' "' +
                            error.response.statusText +
                            '"',
                    );
                }
            },

            handleOnCreateResponseError(error) {
                this.handleResponseError(error);
            },

            handleOnUpdateResponseError(error) {
                if (error.response && error.response.status == 409) {
                    Nova.error(
                        this.__(
                            "Another user has updated this resource since this page was loaded. Please refresh the page and try again.",
                        ),
                    );
                } else {
                    this.handleResponseError(error);
                }
            },

            resetErrors() {
                this.validationErrors = new Errors();
            },

            handleFileUploadFinished() {
                this.fileUploadsCount--;

                if (this.fileUploadsCount < 1) {
                    this.fileUploadsCount = 0;
                    this.isWorking = false;
                }
            },

            handleFileUploadStarted() {
                this.isWorking = true;
                this.fileUploadsCount++;
            },
        },
    };
</script>
