<template>
    <table-item :field="field">
        <slot name="field-value">
            <popper
                placement="top-start"
                trigger="hover"
            >
                <template #trigger>
                    <div class="cursor-pointer flex items-center gap-1">
                        <span
                            class="underline decoration-dotted decoration-1 underline-offset-4"
                            >{&hellip;}</span
                        >
                        <span
                            v-if="count !== null"
                            v-text="`(${count})`"
                        />
                    </div>
                </template>
                <template #content>
                    <div class="rounded-lg shadow">
                        <div
                            class="bg-gray-700 text-white py-1 px-3 rounded-t-lg border border-gray-800"
                            v-text="field.name"
                        />
                        <div
                            class="bg-white p-3 rounded-b-lg border border-gray-300"
                        >
                            <div class="grid grid-cols-2 gap-y-2">
                                <div
                                    v-for="option in value"
                                    :key="option.name"
                                >
                                    <label
                                        class="inline-flex items-center gap-x-2 font-normal"
                                    >
                                        <icon
                                            :class="
                                                option.checked
                                                    ? 'text-green-500'
                                                    : 'text-red-500'
                                            "
                                            :name="
                                                option.checked
                                                    ? 'mdi-check-circle'
                                                    : 'heroicon-c-x-circle'
                                            "
                                        />
                                        {{ option.label }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </popper>
        </slot>
    </table-item>
</template>

<script>
    import filter from "lodash/filter";
    import map from "lodash/map";

    export default {
        props: ["field"],

        data: () => ({
            value: [],
        }),

        computed: {
            count() {
                return this.value.filter((v) => v.checked).length;
            },
        },

        created() {
            const value = this.field.value || {};

            this.value = filter(
                map(this.field.options, (o) => {
                    return {
                        name: o.name,
                        label: o.label,
                        checked: value[o.name] || false,
                    };
                }),
                (o) => {
                    if (
                        this.field.hideFalseValues === true &&
                        o.checked === false
                    ) {
                        return false;
                    } else if (
                        this.field.hideTrueValues === true &&
                        o.checked === true
                    ) {
                        return false;
                    }

                    return true;
                },
            );
        },
    };
</script>
