<template>
    <div
        class="absolute inset-0 flex flex-col gap-2 items-center justify-center p-4"
    >
        <h4 class="m-0 p-0 text-red-500">Failed to load {{ name }}!</h4>
        <div
            class="text-gray-500 text-xs italic"
            v-text="errorMessage"
        ></div>
        <v-button
            class="gap-1 text-sm"
            severity="alert"
            @click="$emit('click')"
        >
            <icon
                class="!w-3 !h-3"
                name="heroicon-c-arrow-path"
            />
            <span>Reload</span>
        </v-button>
    </div>
</template>

<script>
    import Icon from "@nova/components/Icon.vue";
    import VButton from "@nova/components/Buttons/VButton.vue";

    export default {
        components: {
            Icon,
            VButton,
        },

        props: {
            name: {
                type: String,
                required: true,
            },

            /**
             * @link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error/Error
             * @link https://github.com/axios/axios/blob/v1.x/lib/core/AxiosError.js
             *
             * @property {Error|AxiosError}
             */
            error: {
                type: Object,
                required: true,
            },
        },

        emits: ["click"],

        computed: {
            errorMessage() {
                return (
                    this.error.response.data?.message ||
                    this.error.statusText ||
                    this.error.message
                );
            },
        },
    };
</script>
