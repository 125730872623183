import BaseEditor from "./BaseEditor";

class Regular extends BaseEditor {}

Regular.prototype.toolbarButtons = [
    "undo",
    "redo",
    "|",
    "bold",
    "italic",
    "underline",
    "strikeThrough",
    "subscript",
    "superscript",
    "clearFormatting",
    "|",
    "formatOL",
    "formatUL",
    "|",
    "outdent",
    "indent",
    "|",
    "quote",
    "|",
    "align",
    "|",
    "insertLink",
    "|",
    "lineHeight",
    "paragraphFormat",
    "paragraphStyle",
    "inlineStyle",
    "|",
    "fontSize",
    "fontFamily",
    "textColor",
    "backgroundColor",
    "|",
    "quote",
    "insertFile",
    "insertHR",
    "|",
    "html",
];

export default Regular;
