<template>
    <div class="relative">
        <icon
            class="absolute m-1.5 text-gray-500"
            name="mdi-magnify"
        />

        <input
            :aria-label="__('Search')"
            class="form-control pl-8 rounded-full text-black"
            dusk="search-input"
            :placeholder="__('Search')"
            spellcheck="false"
            type="search"
            :value="keyword"
            @input="handleChange"
        />
    </div>
</template>

<script>
    import Icon from "@nova/components/Icon.vue";

    export default {
        components: {
            Icon,
        },

        props: {
            keyword: {
                type: String,
            },
        },

        emits: ["update:keyword"],

        methods: {
            handleChange(event) {
                this.$emit("update:keyword", event?.target?.value || "");
            },
        },
    };
</script>
