import dayjs from "./dayjs";

const USER_TIMEZONE = dayjs.tz.guess();

class DayJsFormatter {
    constructor(value, format, timezone, defaults) {
        this.value = value || new Date().toString();
        this.format = format || defaults.formats.datetime;
        this.timezone = timezone || defaults.timezones.eagleTz;
        this.defaults = defaults;
        console.log(value, format, timezone, defaults);
    }

    usingValue(value) {
        this.value = value;
        return this;
    }

    usingFormat(format) {
        this.format = format;
        return this;
    }

    usingTimezone(timezone) {
        this.timezone = timezone;
        return this;
    }

    toDate() {
        this.format = this.defaults.formats.date;
        return this;
    }

    toTime() {
        this.format = this.defaults.formats.time;
        return this;
    }

    toDateTime() {
        this.format = this.defaults.formats.datetime;
        return this;
    }

    toDateTimeTz() {
        this.format = this.defaults.formats.datetimeTz;
        return this;
    }

    toAppTz() {
        this.timezone = this.defaults.timezones.appTz;
        return this;
    }

    toEagleTz() {
        this.timezone = this.defaults.timezones.eagleTz;
        return this;
    }

    toUserTz() {
        this.timezone = this.defaults.timezones.userTz || USER_TIMEZONE;
        return this;
    }

    dayjs() {
        return dayjs(this.value);
    }

    display() {
        return this.dayjs().tz(this.timezone, false).format(this.format);
    }

    toString() {
        return this.display();
    }
}

export default DayJsFormatter;

function date(value, format, timezone, options) {
    return new DayJsFormatter(value, format, timezone, options);
}

export { date, DayJsFormatter };
