export default {
    name: "fileSelector",
    icon: { NAME: "fileManager", SVG_KEY: "fileManager" },
    command: {
        title: "File Selector",
        focus: false,
        undo: false,
        refreshAfterCallback: false,
        callback: function () {
            const editor = this;

            $.ajax({
                url: "/file/select.php",
                dataType: "html",
                type: "GET",
            }).done(function (resp) {
                $("#selector-modal").html(resp).foundation("open");
            });

            $("#selector-modal[data-reveal]").on(
                "closed.zf.reveal",
                function () {
                    const selected = {};
                    $("#selector-modal")
                        .contents()
                        .find("input.selected")
                        .each(function () {
                            selected[$(this).attr("name")] = $(this).val();
                        });

                    if (selected.filekey) {
                        const href = `/file/download.php?id=${selected.filekey}`;

                        editor.selection.restore();
                        editor.html.insert(
                            `<a href="${href}" title="${selected.name}">${selected.name}</a>`,
                        );
                    }
                },
            );
        },
    },
};
