<template>
    <div
        class="relative"
        :class="{ 'overflow-hidden': loading }"
    >
        <!-- Default -->
        <template v-if="variant === 'default'">
            <div
                v-if="loading"
                class="flex items-center justify-center z-30 p-6 min-h-24"
            >
                <component-loader />
            </div>
            <slot v-else />
        </template>

        <!-- Overlay -->
        <template v-else>
            <div
                v-if="loading"
                class="absolute inset-0 z-20 bg-white/50 dark:bg-gray-800/50 flex items-center justify-center"
                dusk="loading-view"
            >
                <component-loader />
            </div>

            <slot />
        </template>
    </div>
</template>

<script>
    import ComponentLoader from "@nova/components/Icons/ComponentLoader.vue";

    export default {
        components: {
            ComponentLoader,
        },

        props: {
            loading: {
                type: Boolean,
                default: true,
            },

            variant: {
                type: String,
                default: "default",
                validator: (v) => ["default", "overlay"].includes(v),
            },
        },
    };
</script>
