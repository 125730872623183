<template>
    <pre v-html="display"></pre>
</template>

<script>
    import { prettyPrintJson } from "pretty-print-json";

    export default {
        props: ["value"],

        computed: {
            display() {
                return prettyPrintJson.toHtml(this.value, { quoteKeys: true });
            },
        },
    };
</script>
