import BaseEditor from "./BaseEditor";

class ReleaseNotes extends BaseEditor {}

ReleaseNotes.prototype.toolbarButtons = [
    "html",
    "|",
    "undo",
    "redo",
    "|",
    "selectAll",
    "|",
    "bold",
    "italic",
    "underline",
    "strikeThrough",
    "clearFormatting",
    "|",
    "align",
    "|",
    "lineHeight",
    "paragraphFormat",
    "paragraphStyle",
    "inlineStyle",
    "|",
    "formatOL",
    "formatUL",
    "|",
    "outdent",
    "indent",
    "|",
    "quote",
    "|",
    "insertLink",
    "insertTable",
    "insertHR",
    "fileSelector",
    "|",
    "fontSize",
    "fontFamily",
    "textColor",
    "backgroundColor",
    "subscript",
    "superscript",
    "|",
    "fullscreen",
    "getPDF",
    "|",
    "trackChanges",
    "help",
];

export default ReleaseNotes;
