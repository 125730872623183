import Asset from "./plugins/Asset.js";
import DayJsVue from "./plugins/DayJsVue.js";
import { Nova } from "@vendor/eagle-metal/nova-vue/resources/js";
import RegisterComponents from "./plugins/RegisterComponents.js";
import ShellLayout from "@/layouts/ShellLayout.vue";
import { ZiggyVue } from "@vendor/tightenco/ziggy/src/js";
import { primeVueConfig } from "../vue/plugins/RegisterPrimeVue.js";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";

const Pinia = createPinia();
Pinia.use(piniaPluginPersistedstate);

export class App extends Nova {
    inertiaPages() {
        return _.mapKeys(this.pagesGlob(), (v, k) =>
            k.replace(/\.\/pages\/(.*)\.vue/, "$1").replace(/\//g, "."),
        );
    }

    inertiaOptions(theme) {
        return {
            ...super.inertiaOptions(theme),
            id: "inertia-app",
            progress: {
                color: theme.colors.blue[400],
            },
        };
    }

    inertiaLayout() {
        return ShellLayout;
    }

    vueSetup(theme) {
        super.vueSetup(theme);

        this.app
            .use(ZiggyVue)
            .use(DayJsVue, this.config("dayjs"))
            .use(Asset)
            .use(RegisterComponents)
            .use(Pinia)
            .use(ConfirmationService)
            .use(ToastService);

        if (this.domainPlugins) {
            this.domainPlugins().forEach((plugin) => {
                this.app.use(plugin);
            });
        }
    }

    primeVueConfig() {
        return primeVueConfig;
    }
}

export function createInertiaApp(config) {
    return new App(config);
}
