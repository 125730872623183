<template>
    <div
        v-if="text"
        v-tooltip.top="{
            value: title
                ? `
                    <div class='bg-gray-200 px-2 py-1 text-base'>
                        ${title}
                    </div>
                    <div class='px-3 py-2'>
                        ${text}
                    </div>
                `
                : `<div class='px-3 py-2'>${text}</div>`,
            escape: false,
            unstyled: true,
            pt: {
                root: {
                    class: 'absolute p-1 min-w-40 pointer-events-none',
                    style: {
                        maxWidth: maxWidthStyle,
                    },
                },
                text: {
                    class: [
                        'border border-gray-300 rounded-md shadow-none',
                        'text-sm bg-white text-gray-900',
                    ],
                    style: {
                        marginRight: '40%',
                        marginLeft: '-40%',
                    },
                },
                arrow: {
                    class: [
                        'absolute -ml-2 -mb-1 w-4 h-4 rotate-45',
                        'bg-white border border-gray-300',
                    ],
                    style: {
                        borderTop: 'none',
                        borderLeft: 'none',
                    },
                },
            },
        }"
        class="z-20"
    >
        <icon
            class="w-4 h-4 cursor-pointer text-gray-500"
            :name="icon"
        />
    </div>
</template>

<script>
    import Icon from "@nova/components/Icon.vue";

    export default {
        components: {
            Icon,
        },

        props: {
            icon: {
                type: String,
                default: "mdi-information-slab-circle-outline",
            },

            title: String,

            text: {
                type: String,
                required: true,
            },

            width: {
                type: [String, Number],
                default: "auto",
            },
        },

        computed: {
            maxWidthStyle() {
                return this.width === "auto" ? this.width : `${this.width}px`;
            },
        },
    };
</script>
