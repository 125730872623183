<template>
    <div
        class="flex flex-col"
        :class="{
            'bg-white text-black border-gray-200': true,
            'dark:bg-gray-900 dark:text-white dark:border-gray-700': true,
            'border rounded-lg shadow-lg overflow-hidden': true,
            'max-w-sm lg:max-w-lg': width === 'auto',
        }"
        :style="styles"
    >
        <slot />
    </div>
</template>

<script>
    export default {
        props: {
            width: {
                default: 120,
            },
        },

        computed: {
            styles() {
                return {
                    width: this.width === "auto" ? "auto" : `${this.width}px`,
                };
            },
        },
    };
</script>
