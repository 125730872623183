<template>
    <div class="flex flex-col gap-2">
        <h4
            v-if="title"
            class="m-0 p-0"
            v-text="title"
        />
        <div class="flex-1">
            <canvas
                ref="chart"
                :class="{
                    'border-b': style === 'bar',
                }"
                :style="`border-color: ${colors.grid}`"
            ></canvas>
        </div>
    </div>
</template>

<script>
    import BaseChartMetric from "./BaseChartMetric.vue";

    export default {
        extends: BaseChartMetric,

        computed: {
            style() {
                return this.data.style || "line";
            },

            tension() {
                switch (this.data.rounded) {
                    case "lg":
                        return 0.5;
                    case "md":
                        return 0.25;
                    case "sm":
                        return 0.1;
                    case "none":
                    default:
                        return 0;
                }
            },

            chartType() {
                return "bar";
            },

            chartData() {
                if (!this.data?.trend) {
                    return {};
                }

                return {
                    labels: Object.keys(this.data.trend),
                    datasets: [
                        {
                            type: this.style,
                            data: Object.values(this.data.trend),
                            borderColor: this.colors.primary,
                            backgroundColor: this.colors.primary,
                            pointRadius: 0,
                            hoverPointRadius: 5,
                            categoryPercentage: 1,
                            barPercentage: 0.95,
                            tension: this.tension,
                        },
                    ],
                };
            },

            chartOptions() {
                return {
                    interaction: {
                        mode: "index",
                        intersect: false,
                    },
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            display: this.style === "line",
                            grid: {
                                display: false,
                            },
                        },
                        y: {
                            display: this.style === "line",
                            grid: {
                                tickColor: "white",
                            },
                            border: {
                                display: false,
                            },
                            position: "right",
                            ticks: {
                                maxTicksLimit: 5,
                                callback: (value) => {
                                    return this.formatNumber(
                                        value,
                                        this.format,
                                    );
                                },
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            ...this.chartTooltip,
                            callbacks: {
                                label: (context) => {
                                    if (this.suffix) {
                                        return `${this.suffix}: ${context.formattedValue}`;
                                    }

                                    return context.formattedValue;
                                },
                            },
                        },
                    },
                };
            },
        },
    };
</script>
