<template>
    <nav class="rounded-b-lg font-bold flex items-center">
        <button-group>
            <!-- First Link -->
            <v-button
                :disabled="!hasPreviousPages || linksDisabled"
                dusk="first"
                label="&laquo;"
                rel="first"
                severity="muted"
                variant="outline"
                @click.prevent="selectPage(1)"
            />

            <!-- Previous Link -->
            <v-button
                :disabled="!hasPreviousPages || linksDisabled"
                dusk="previous"
                label="&lsaquo;"
                rel="prev"
                severity="muted"
                variant="outline"
                @click.prevent="selectPreviousPage()"
            />

            <!-- Pages Links -->
            <v-button
                v-for="(n, i) in printPages"
                :key="n"
                :class="{
                    'bg-blue-50': page === n,
                    'border-r-2': page === n || i == 4,
                    'border-l-0': page === n - 1,
                }"
                :disabled="linksDisabled"
                :dusk="`page:${n}`"
                :label="n"
                :severity="page === n ? 'primary' : 'muted'"
                variant="outline"
                @click.prevent="selectPage(n)"
            />

            <!-- Next Link -->
            <v-button
                class="border-l-0"
                :disabled="!hasMorePages || linksDisabled"
                dusk="next"
                label="&rsaquo;"
                rel="next"
                severity="muted"
                variant="outline"
                @click.prevent="selectNextPage()"
            />

            <!-- Last Link -->
            <v-button
                :disabled="!hasMorePages || linksDisabled"
                dusk="last"
                label="&raquo;"
                rel="last"
                severity="muted"
                variant="outline"
                @click.prevent="selectPage(pages)"
            />
        </button-group>

        <slot />
    </nav>
</template>

<script>
    import ButtonGroup from "@nova/components/Buttons/ButtonGroup.vue";
    import VButton from "@nova/components/Buttons/VButton.vue";

    export default {
        components: {
            ButtonGroup,
            VButton,
        },

        props: {
            page: {
                type: Number,
                required: true,
            },

            pages: {
                type: Number,
                default: 0,
            },

            next: {
                type: Boolean,
                default: false,
            },

            previous: {
                type: Boolean,
                default: false,
            },
        },

        emits: ["page"],
        data: () => ({ linksDisabled: false }),

        computed: {
            hasPreviousPages: function () {
                return this.page > 1;
            },

            hasMorePages: function () {
                return this.page < this.pages;
            },

            printPages() {
                const middlePage = Math.min(
                        Math.max(3, this.page),
                        this.pages - 2,
                    ),
                    fromPage = Math.max(middlePage - 2, 1),
                    toPage = Math.min(middlePage + 2, this.pages);

                let pages = [];

                for (let n = fromPage; n <= toPage; ++n) {
                    if (n > 0) pages.push(n);
                }

                return pages;
            },
        },

        mounted() {
            Nova.$on("resources-loaded", this.listenToResourcesLoaded);
        },

        beforeUnmount() {
            Nova.$off("resources-loaded", this.listenToResourcesLoaded);
        },

        methods: {
            selectPage(page) {
                if (this.page != page) {
                    this.linksDisabled = true;
                    this.$emit("page", page);
                }
            },

            selectPreviousPage() {
                this.selectPage(this.page - 1);
            },

            selectNextPage() {
                this.selectPage(this.page + 1);
            },

            listenToResourcesLoaded() {
                this.linksDisabled = false;
            },
        },
    };
</script>
