<template>
    <card
        class="flex flex-col justify-center items-center gap-2 p-6 text-red-400"
    >
        <icon
            class="w-20 h-20"
            name="mdi-table-remove"
        />

        <h3 class="m-0 p-0">
            {{
                __("Failed to load :resource!", {
                    resource: __(`${resource.label}`),
                })
            }}
        </h3>

        <v-button
            class="shrink-0"
            :label="__('Reload')"
            severity="alert"
            size="sm"
            variant="outline"
            @click="$emit('click')"
        />
    </card>
</template>

<script>
    import Card from "@nova/components/Card.vue";
    import Icon from "@nova/components/Icon.vue";
    import VButton from "@nova/components/Buttons/VButton.vue";

    export default {
        components: {
            Card,
            Icon,
            VButton,
        },

        props: {
            resource: {
                type: Object,
                required: true,
            },
        },

        emits: ["click"],
    };
</script>
