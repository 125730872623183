<template>
    <div>
        <boolean-icon
            :class="alignClass"
            :value="field.value"
        />
    </div>
</template>

<script>
    import DisplayField from "@nova/extendables/DisplayField.vue";

    export default {
        extends: DisplayField,
    };
</script>
