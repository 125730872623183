<template>
    <icon
        :class="color"
        :name="icon"
    />
</template>

<script>
    import Icon from "@nova/components/Icon.vue";

    export default {
        components: {
            Icon,
        },

        props: ["value"],

        computed: {
            icon() {
                return this.value ? "mdi-check-circle" : "heroicon-c-x-circle";
            },

            color() {
                return this.value ? "text-green-500" : "text-red-500";
            },
        },
    };
</script>
