<template>
    <div
        v-if="
            shouldShowCheckboxes ||
            shouldShowDeleteMenu ||
            softDeletes ||
            !viaResource ||
            hasFilters ||
            haveStandaloneActions ||
            resourceInformation?.searchable
        "
        class="flex flex-col gap-2 bg-gray-700 text-white px-4 py-2 rounded-t-md"
    >
        <div class="flex items-center gap-4">
            <!-- Select All Dropdown -->
            <select-all-dropdown
                v-if="shouldShowCheckboxes"
                :all-matching-resource-count="allMatchingResourceCount"
                :current-page-count="resources.length"
                @deselect="$emit('deselect-all', $event)"
                @toggle-select-all="$emit('select-all', $event)"
                @toggle-select-all-matching="
                    $emit('select-all-matching', $event)
                "
            />

            <!-- Search -->
            <div
                v-if="searchable"
                class="hidden md:block w-full flex-1 lg:flex-none md:w-1/3 2xl:w-1/4"
            >
                <index-search-input
                    :keyword="search"
                    @update:keyword="$emit('search', $event)"
                />
            </div>

            <div class="block md:hidden lg:block flex-1" />

            <!-- Action Selector -->
            <div
                v-if="shouldShowActionSelector"
                class="hidden lg:block lg:w-1/4 xl:w-1/6"
            >
                <action-selector
                    :actions="availableActions"
                    :endpoint="actionEndpoint"
                    :pivot-actions="pivotActions"
                    :pivot-name="pivotName"
                    :resource-name="resourceName"
                    :selected-resources="selectedResourcesForActionSelector"
                    :via-relationship="actionQueryString.viaRelationship"
                    :via-resource="actionQueryString.viaResource"
                    :via-resource-id="actionQueryString.viaResourceId"
                    @action-executed="$emit('action-executed', $event)"
                />
            </div>

            <!-- Resource Polling -->
            <v-button
                v-if="shouldShowPollingToggle"
                :icon="currentlyPolling ? 'mdi-sync' : 'mdi-clock'"
                :icon-class="{
                    'animate-spin-slow -scale-100': currentlyPolling,
                }"
                padding="tight"
                :severity="currentlyPolling ? 'info' : 'light'"
                :title="
                    currentlyPolling ? __('Stop Polling') : __('Start Polling')
                "
                :variant="currentlyPolling ? 'solid' : 'outline'"
                @click="$emit('polling-toggled', $event)"
            />

            <!-- Lenses -->
            <lens-selector
                v-if="lenses?.length > 0"
                :lenses="lenses"
                :resource-name="resourceName"
            />

            <!-- Filter Menu -->
            <filter-menu
                v-if="filters.length > 0 || softDeletes || !viaResource"
                :active-filter-count="activeFilterCount"
                :filters="filters"
                :filters-are-applied="filtersAreApplied"
                :per-page="perPage"
                :per-page-options="filterPerPageOptions"
                :soft-deletes="softDeletes"
                :trashed="trashed"
                @clear-selected-filters="
                    $emit('clear-selected-filters', $event)
                "
                @dock-changed="$emit('filter-dock-changed', $event)"
                @filter-changed="$emit('filter-changed', $event)"
                @per-page-changed="$emit('per-page-changed', $event)"
                @trashed-changed="$emit('trashed-changed', $event)"
            />

            <!-- Delete Menu -->
            <delete-menu
                v-if="shouldShowDeleteMenu"
                :all-matching-resource-count="allMatchingResourceCount"
                :all-matching-selected="selectAllMatchingChecked"
                class="flex"
                dusk="delete-menu"
                :resources="resources"
                :selected-resources="selectedResources"
                :soft-deletes="softDeletes"
                @close="$emit('close-delete-modal', $event)"
                @delete-all-matching="$emit('delete-all-matching', $event)"
                @delete-selected="$emit('delete-selected', $event)"
                @force-delete-all-matching="
                    $emit('force-delete-all-matching', $event)
                "
                @force-delete-selected="$emit('force-delete-selected', $event)"
                @restore-all-matching="$emit('restore-all-matching', $event)"
                @restore-selected="$emit('restore-selected', $event)"
            />
        </div>

        <!-- Mobile Search -->
        <div
            v-if="resourceInformation && resourceInformation.searchable"
            class="block md:hidden -ml-2"
        >
            <slot name="search" />
        </div>

        <!-- Mobile Action Selector -->
        <div
            v-if="shouldShowActionSelector"
            class="block lg:hidden -ml-2"
        >
            <action-selector
                :actions="availableActions"
                :endpoint="actionEndpoint"
                :pivot-actions="pivotActions"
                :pivot-name="pivotName"
                :resource-name="resourceName"
                :selected-resources="selectedResourcesForActionSelector"
                :via-relationship="actionQueryString.viaRelationship"
                :via-resource="actionQueryString.viaResource"
                :via-resource-id="actionQueryString.viaResourceId"
                @action-executed="$emit('action-executed', $event)"
            />
        </div>
    </div>
</template>

<script>
    import ActionSelector from "@nova/components/ActionSelector.vue";
    import DeleteMenu from "@nova/components/DeleteMenu.vue";
    import FilterMenu from "@nova/components/FilterMenu.vue";
    import IndexSearchInput from "@nova/components/Inputs/IndexSearchInput.vue";
    import LensSelector from "@nova/components/LensSelector.vue";
    import SelectAllDropdown from "@nova/components/Dropdowns/SelectAllDropdown.vue";
    import VButton from "@nova/components/Buttons/VButton.vue";

    export default {
        components: {
            ActionSelector,
            DeleteMenu,
            FilterMenu,
            IndexSearchInput,
            LensSelector,
            SelectAllDropdown,
            VButton,
        },

        inject: [
            "actionQueryString",
            "allMatchingResourceCount",
            "availableActions",
            "currentlyPolling",
            "hasFilters",
            "haveStandaloneActions",
            "perPage",
            "perPageOptions",
            "pivotActions",
            "pivotName",
            "resourceInformation",
            "resourceName",
            "resources",
            "search",
            "searchable",
            "selectAllChecked",
            "selectAllMatchingChecked",
            "selectedResources",
            "selectedResourcesForActionSelector",
            "shouldShowActionSelector",
            "shouldShowCheckboxes",
            "shouldShowDeleteMenu",
            "shouldShowPollingToggle",
            "softDeletes",
            "trashed",
            "viaResource",
        ],

        props: {
            lenses: {
                type: Array,
                default: () => [],
            },

            actionEndpoint: {
                type: String,
                default: null,
            },
        },

        emits: [
            "action-executed",
            "clear-selected-filters",
            "close-delete-modal",
            "delete-all-matching",
            "delete-selected",
            "deselect-all",
            "filter-changed",
            "filter-dock-changed",
            "force-delete-all-matching",
            "force-delete-selected",
            "per-page-changed",
            "polling-toggled",
            "restore-all-matching",
            "restore-selected",
            "search",
            "select-all",
            "select-all-matching",
            "trashed-changed",
        ],

        computed: {
            filters() {
                return this.$store.getters[`${this.resourceName}/filters`];
            },

            filtersAreApplied() {
                return this.$store.getters[
                    `${this.resourceName}/filtersAreApplied`
                ];
            },

            activeFilterCount() {
                return this.$store.getters[
                    `${this.resourceName}/activeFilterCount`
                ];
            },

            filterPerPageOptions() {
                if (this.resourceInformation) {
                    return (
                        this.perPageOptions ||
                        this.resourceInformation.perPageOptions
                    );
                }

                return [];
            },
        },
    };
</script>
