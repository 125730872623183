<template>
    <loading-card :loading="loading">
        <component
            :is="component"
            v-if="attrs.data"
            :title="card.name"
            v-bind="{
                ...$attrs,
                ...attrs,
            }"
        >
            <template
                v-if="$slots.controls"
                #controls
            >
                <slot
                    :card="card"
                    name="controls"
                ></slot>
            </template>
            <template #help>
                <help-text-tooltip
                    v-if="card.helpText"
                    :text="card.helpText"
                    :width="card.helpWidth"
                />
            </template>
        </component>

        <metric-error-dialog
            v-if="error"
            :error="error"
            :name="card.name"
            @click="fetch"
        />
    </loading-card>
</template>

<script>
    import BaseCompositeMetric from "@nova/components/Metrics/Base/BaseCompositeMetric.vue";
    import BaseControlMetric from "@nova/components/Metrics/Base/BaseControlMetric.vue";
    import BaseHistogramMetric from "@nova/components/Metrics/Base/BaseHistogramMetric.vue";
    import BaseLeaderboardMetric from "@nova/components/Metrics/Base/BaseLeaderboardMetric.vue";
    import BaseMultiValueTrendMetric from "@nova/components/Metrics/Base/BaseMultiValueTrendMetric.vue";
    import BaseParetoMetric from "@nova/components/Metrics/Base/BaseParetoMetric.vue";
    import BasePartitionMetric from "@nova/components/Metrics/Base/BasePartitionMetric.vue";
    import BaseTrendMetric from "@nova/components/Metrics/Base/BaseTrendMetric.vue";
    import BaseValueMetric from "@nova/components/Metrics/Base/BaseValueMetric.vue";
    import HelpTextTooltip from "@nova/components//HelpTextTooltip.vue";
    import LoadingCard from "@nova/components/LoadingCard.vue";
    import MetricErrorDialog from "@nova/components/Metrics/MetricErrorDialog.vue";

    export default {
        components: {
            BaseCompositeMetric,
            BaseControlMetric,
            BaseHistogramMetric,
            BaseLeaderboardMetric,
            BaseMultiValueTrendMetric,
            BaseParetoMetric,
            BasePartitionMetric,
            BaseTrendMetric,
            BaseValueMetric,
            HelpTextTooltip,
            LoadingCard,
            MetricErrorDialog,
        },

        props: {
            component: {
                type: String,
                required: true,
            },

            card: {
                type: Object,
                default: () => ({}),
            },

            params: {
                type: Object,
                default: () => ({}),
            },
        },

        data: () => ({
            loading: true,
            attrs: {},
            error: null,
        }),

        computed: {
            payload() {
                return {
                    params: {
                        ...this.card.params,
                        ...this.params,
                    },
                };
            },
        },

        watch: {
            endpoint() {
                this.fetch();
            },

            payload() {
                this.fetch();
            },
        },

        created() {
            this.fetch();
        },

        methods: {
            fetch() {
                this.loading = true;
                this.error = null;

                this.$http
                    .get(this.card.endpoint, this.payload)
                    .then(({ data }) => {
                        const value = this.card.dataKey
                            ? data[this.card.dataKey]
                            : data;

                        this.attrs = value;
                    })
                    .catch((error) => {
                        this.error = error;
                        console.error(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
        },
    };
</script>
