<template>
    <table-item :field="field">
        <template #value="{ item }">
            <popper
                placement="top-start"
                trigger="hover"
            >
                <template #trigger>
                    <div class="cursor-pointer flex items-center gap-1">
                        <span
                            class="underline decoration-dotted decoration-1 underline-offset-4"
                            >{&hellip;}</span
                        >
                        <span
                            v-if="field.count !== null"
                            v-text="`(${field.count})`"
                        />
                    </div>
                </template>
                <template #content>
                    <div class="rounded-lg shadow">
                        <div
                            class="bg-gray-700 text-white py-1 px-3 rounded-t-lg border border-gray-800"
                            v-text="field.name"
                        />
                        <div
                            class="bg-white p-3 rounded-b-lg border border-gray-300"
                        >
                            <json :value="json(item)" />
                        </div>
                    </div>
                </template>
            </popper>
        </template>
    </table-item>
</template>

<script>
    export default {
        props: ["field"],

        methods: {
            json(item) {
                return JSON.parse(item.fieldValue);
            },
        },
    };
</script>
